import { Button } from "primereact/button";
import React from "react";
import { Link } from "react-router-dom";
import { useUserState } from '../context/UserContext';

const Error403 = () => {

  const { userState } = useUserState();

  return (
    <div className="surface-section px-4 py-8 md:px-6 lg:px-8">
      <div
        style={{
          background:
            "radial-gradient(circle, rgba(2,0,36,1) 0%, rgba(41,221,202,1) 0%, rgba(129,245,229,0.7637429971988796) 0%, rgba(0,212,255,0) 90%)",
        }}
        className="text-center"
      >
        <span className="bg-white text-blue-500 font-bold text-2xl inline-block px-3">
          403
        </span>
      </div>
      <div className="mt-6 mb-5 font-bold text-6xl text-900 text-center">
        No tiene permisos suficientes
      </div>
      <p className="text-700 text-3xl mt-0 mb-6 text-center">
        Lo sentimos, la página que intenta acceder requiere mas permisos de los que tiene actualmente.
      </p>
      <div className="text-center">
        <Link to="/dashboard" className="no-underline">
          <Button
            className="p-button-text mr-2"
            label="Ir al dashboard"
            icon="pi pi-arrow-left"
          />
        </Link>

        {
        Boolean(userState.user.token) === false
        
        ? <>
        
        <Link to="/" className="no-underline">
          <Button label="Identifícate" icon="pi pi-user" />
        </Link>
        </>
        
        : <></>  
        }
      </div>
    </div>
  );
};

export default Error403;
