import { useUserState} from '../../context/UserContext';


const VisiblePorRol = ({children, rol}) => {
  const { userState } = useUserState();

  if (!userState.user.roles.includes(rol) &&
      !userState.user.roles.includes('ROLE_'+rol)) {
    return <></>;
  }
  return children;
};

export default VisiblePorRol