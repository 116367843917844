/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";

import { ApiConstants, getApi } from '../../api';
import { useUserState } from '../../context/UserContext';


const useContrato = () => {
    const { userState } = useUserState();

    const { id } = useParams();

    const [contrato, setContrato] = useState([]);
    const [cargando, setCargando] = useState(true);
    const [editando, setEditando] = useState(false);

    const mostrarBotonEditar = userState.user.roles.includes('ROLE_ADMIN');
    

    const editar = () => {
        setEditando(true);
    }

    const noEditar = () => {
      setEditando(false);
    }

    useEffect(() => {
        getApi(ApiConstants.CONTRATOS.DATOS, userState.user.token, null, id)
        .then(response => {
            setContrato(response.data.data);
            setCargando(false);
        });
    }, [userState.user.token]);
  
    return {
        contrato,
        setContrato,
        cargando,
        mostrarBotonEditar,
        editar,
        noEditar,
        editando
    }
};

export default useContrato;