import { useState, useEffect } from 'react';

import { ApiConstants, getApi } from '../../api';
import { useUserState } from '../../context/UserContext';

const useProvincias = (filtro) => {
    const { userState } = useUserState();
    const [provincias, setProvincias] = useState([]);
    const [cargando, setCargando] = useState(true);
    const [creando, setCreando] = useState(false);

    const [status, setStatus] = useState(0);
    const [statusText, setStatusText] = useState('');
    const [totalItems, setTotalItems] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [filtroProvincias, setFiltroProvincias] = useState(filtro);


    const nuevaProvincia = () => {
        setCreando(true);
    }

    const dejarDeCrear = () => {
        setCreando(false);
    }

    const loadProvincias = () => {
        if (filtroProvincias?.id>0){
            getApi(ApiConstants.PROVINCIAS.LISTA, userState.user.token, null, filtroProvincias?.id)
            .then(response => {
            if (response){
                setProvincias([...response.data.data]);
            
                setTotalItems(response.data.totalItems);
                setTotalPages(response.data.totalPages);
                setCurrentPage(response.data.currentPage);
                setStatus(response.data.status);
                setStatusText(response.data.statusText);
            
                setCargando(false);
            }
            });
        }
    }

    useEffect(() => {
        loadProvincias();
    }, [filtroProvincias, userState.user.token]);
    

    return {
        cargando,
        creando,
        dejarDeCrear,
        loadProvincias,
        nuevaProvincia,
        totalItems,
        totalPages,
        currentPage,
        status,
        statusText,
        setFiltroProvincias,
        provincias
    }

};

export default useProvincias;