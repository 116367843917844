import React, { useState,  useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { Toolbar } from 'primereact/toolbar';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ProgressSpinner } from 'primereact/progressspinner';
import { InputText } from 'primereact/inputtext';
import { Button } from "primereact/button";
import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';

import Card from "../../components/Cards/Card";
import useContratos from './useContratos';
import ContratoForm from '../Contrato/Form/ContratoForm';
import { FechaBonicaAtrasadaResaltada , formatFecha} from '../../components/Fechas/FechaBonica';
import {UserContext} from '../../context/UserContext';
import { ApiConstants, postApi } from '../../api';
import Paginador from '../../components/Paginador/Paginador';


let navigate;

const activoTemplate = (data) => {
  return (
    data.activo === true 
      ?
      <i className="pi text-green-500 pi-check-circle"></i>
      :
      <i className="pi text-pink-500 pi-times-circle"></i>
      )
}

const renovarTemplate = (data) => {
  const color = data.renovable === true ? 'green' : 'red';
  const estado = data.renovable === true ? 'Si' : 'No';
  return (
    <span className={`text-${color}-600  p-2`}>{estado}</span>
  )
}

const calculaImporteConDto = (pvp, dto) =>{
  const desc = ((dto * pvp) / 100);
  //return (pvp - desc).toFixed(2);
  return (pvp - desc);
}

const Contratos = (props) => {
  navigate = useNavigate();

  const { userState } = useContext(UserContext);
  const { 
    cargando, 
    totalItems, 
    totalPages, 
    creando, 
    dejarDeCrear, 
    loadItems, 
    nuevoItem, 
    items, 
    filtroItems, 
    setFiltroState 
  } = useContratos(props);

  const [selectedContratos, setSelectedContratos] = useState([]);

  const onSiguiente = () => {
      setFiltroState({...filtroItems, page: (filtroItems.page)+1});
  }

  const onAnterior = () => {
      setFiltroState({...filtroItems, page: (filtroItems.page)-1});
  }

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    if (filtroItems.global!==value){
      setFiltroState({...filtroItems, global: value, page: 0});
    }
  }

  const onChangueSoloActivo= (e) => {
    const valueCode = e.target.checked;
    let valueSoloActivo = undefined;
    if (valueCode === true) {
      valueSoloActivo = true;
    }

    if (filtroItems.activo !== valueSoloActivo) {
       setFiltroState({ ...filtroItems, page: 0, activo: valueSoloActivo });
    }
   }

  const onChangeVencimiento = (event) => {
    const value = event.target.value;
    if (filtroItems.fechaVencimiento===value) return;

    if (value!==null){
        setFiltroState({...filtroItems,  page: 0, fechaVencimiento: value});
    }
    else{
        setFiltroState({...filtroItems,  page: 0, fechaVencimiento: undefined});
    }
    
  }

  const cols = [
      { field: 'cliente', header: 'Cliente'},
      { field: 'fechaVencimiento', header: 'Renovación' },
      { field: 'servicio', header: 'Servicio' },
      { field: 'importe', header: 'Importe' }

  ];

  const exportColumns = cols.map(col => ({ title: col.header, dataKey: col.field}));

  const exportPdf = () => {
    import('jspdf').then(jsPDF => {
        import('jspdf-autotable').then(() => {
            const doc = new jsPDF.default(0, 0);
            const contratosImp = selectedContratos.map(data => (
              {
                cliente: data.cliente.nombreCompleto,
                fechaVencimiento: formatFecha(data.fechaVencimiento),
                servicio: data.servicio.nombre,
                importe: `${calculaImporteConDto(data.servicio.pvp, data.dto)}€`

              }));
            doc.autoTable(exportColumns, contratosImp);
            doc.save('contratos.pdf');
        })
    })
  }

  const exportExcel = () => {
    import('xlsx').then(xlsx => {
      const contratosImp = selectedContratos.map(data => (
        {
          cliente: data.cliente.nombreCompleto,
          fechaVencimiento: formatFecha(data.fechaVencimiento),
          servicio: data.servicio.nombre,
          importe: `${calculaImporteConDto(data.servicio.pvp, data.dto)}€`

        }));
        const worksheet = xlsx.utils.json_to_sheet(contratosImp);
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        saveAsExcelFile(excelBuffer, 'products');
    });
  }

  const saveAsExcelFile = (buffer, fileName) => {
    import('file-saver').then(module => {
        if (module && module.default) {
            let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            let EXCEL_EXTENSION = '.xlsx';
            const data = new Blob([buffer], {
                type: EXCEL_TYPE
            });

            module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
        }
    });
  }



  const generarRecibos = () => {
    const contratosIDs = selectedContratos.map(e => e.id);
    if (contratosIDs.length>0){
      postApi(ApiConstants.CONTRATOS.GENERAR_RECIBOS, userState.user.token, contratosIDs, null)
      .then(response => {
        navigate(`/recibos`);
      });
    } 
  }

  const leftToolBar = (
    <React.Fragment>
      {props.modo!=='cliente' ?
      <>
        <span className="p-input-icon-left mr-2">
          <i className="pi pi-search" />
          <InputText 
            value={filtroItems.global} 
            onChange={onGlobalFilterChange} 
            placeholder="Buscar..." />
        </span>
  <Checkbox 
      inputId="soloActivos"
      onChange={onChangueSoloActivo} 
      checked={filtroItems.activo}
      className="mr-2">
      </Checkbox>
      <label htmlFor="soloActivos" className="p-checkbox-label mr-2">Sólo Activos</label>
   
          <Calendar 
          locale="es"
          dateFormat="dd/mm/yy"
          onChange={onChangeVencimiento} 
          value={filtroItems.fechaVencimiento}
          showIcon 
          showButtonBar
          placeholder='Vencimiento'/>
          <span>&nbsp;</span>
          </>
          : null }
          
          <Button
              icon="pi pi-plus"
              label="Generar Recibos"
              className="p-button-outlined mt-2 md:mt-0"
              disabled={selectedContratos?.length===0}
              onClick={generarRecibos}
            />
          
    </React.Fragment>
  );

  const rightToolBar = (
    <React.Fragment>
    <Button 
      type="button" 
      icon="pi pi-print"
      disabled={selectedContratos?.length===0} 
      onClick={exportPdf} 
      className="p-button-warning mr-2" data-pr-tooltip="Imprimir" />



    <Button 
      type="button" 
      icon="pi pi-file-excel" 
      disabled={selectedContratos?.length===0} 
      onClick={exportExcel} 
      className="p-button-success mr-2" data-pr-tooltip="XLS" />


    <Button
              icon="pi pi-plus"
              label="Nuevo Contrato"
              className="p-button-outlined mt-2 md:mt-0"
              onClick={nuevoItem}
            />
    </React.Fragment>
  );


  if (cargando === true) {
    return (
      <div className="flex h-full align-items-center ">
        <ProgressSpinner/>
      </div>
    );
  }



  return (
    <Card>
      {creando === false
        ? <>
          <h1 className='text-2xl'>Contratos</h1>

          <Toolbar left={leftToolBar} right={rightToolBar} />
          
          <DataTable
            value={items}
            lazy
            responsiveLayout="scroll"
            onRowClick={(e) => {
              const { id } = e.data;
              navigate(`/contrato/${id}`);
            }}

            rowClassName={"hover:bg-blue-50 cursor-pointer"}

            selection={selectedContratos} 
            selectionPageOnly
            onSelectionChange={e => setSelectedContratos(e.value)} 
          >

            <Column selectionMode="multiple" headerStyle={{width: '3em'}}></Column>
            <Column field="cliente.nombreCompleto" header="Cliente" />
            <Column header="Renovación" body={(data) =>  <FechaBonicaAtrasadaResaltada fecha={data.fechaVencimiento} estilo='CORTO'/> } />
            <Column field="periodicidad" header="Periodicidad" />
            <Column field="servicio.nombre" header="Servicio" />
            <Column header="Ren." body={renovarTemplate} />
            <Column field="dto" header="Dto" />
            <Column field="servicio.pvp" header="PVP" />
            <Column header="Activo" body={activoTemplate} align="center" headerStyle={{width: '3em'}}/>
          </DataTable>

          <Paginador 
            nombre="contratos" 
            totalItems={totalItems}
            numPagina={filtroItems?.page}
            totalPaginas={totalPages}
            onAnterior={onAnterior}
            onSiguiente={onSiguiente}
            />

        </>
        :
        <ContratoForm contrato={{ cliente: filtroItems?.cliente }} onUpdate={loadItems} onCerrar={dejarDeCrear} />
      }
    </Card>
  );

};

export default Contratos;
