import Card from "../components/Cards/Card";

const Configuracion = () => {


  return (
    <Card>
  
    </Card>
  );
};

export default Configuracion;