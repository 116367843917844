import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";

import { ApiConstants, getApi } from '../../api';
import { useUserState } from '../../context/UserContext';


const useCliente = () => {
  const { userState } = useUserState();

  const { id } = useParams();

  const [cliente, setCliente] = useState([]);
  const [cargando, setCargando] = useState(true);
  const [editando, setEditando] = useState(false);

  const editar = () => {
    setEditando(true);
  }

  const noEditar = () => {
    setEditando(false);
  }

  useEffect(() => {
    getApi(ApiConstants.CLIENTES.DATOS, userState.user.token, null, id)
      .then(response => {
        setCliente(response.data.data);
        setCargando(false);
      });
  }, [userState.user.token]);


  return {
    cargando,
    cliente,
    setCliente,
    editar,
    noEditar,
    editando
  }
};

export default useCliente;