import React from 'react'
import { HashRouter, Routes, Route } from "react-router-dom";

import AppRoute from './AppRoute';

//Paginas
import Login from "../pages/Login";
import LayoutMain from "../layouts/LayoutMain";
import NotFound from "../pages/NotFound";
import Error403 from "../pages/Error403";
import Dashboard from "../pages/Dashboard";
import Profile from "../pages/Profile";
import Recibos from "../pages/Recibos";
import Recibo from "../pages/Recibo";
import Contratos from "../pages/Contratos";
import Contrato from "../pages/Contrato";
import Clientes from "../pages/Clientes";
import Cliente from "../pages/Cliente";
import Usuarios from "../pages/Usuarios";
import Usuario from "../pages/Usuario";
import Servicios from "../pages/Servicios";
import Servicio from "../pages/Servicio";

import Configuracion from "../pages/Configuracion";


const AppRoutes = () => {

    return (
            <HashRouter>
            <Routes>
                <Route path="/login" element={<AppRoute isNologged={true}><Login /></AppRoute>} />
                <Route path="/" element={<AppRoute isPrivate={true}><LayoutMain /></AppRoute>}>
                    <Route path="" element={<AppRoute isPrivate={true}><Dashboard /></AppRoute>} />
                    <Route path="dashboard" element={<AppRoute isPrivate={true}><Dashboard /></AppRoute>} />
                    <Route path="perfil" element={<AppRoute isPrivate={true}><Profile /></AppRoute>} />
                    <Route path="recibos" element={<AppRoute isPrivate={true}><Recibos /></AppRoute>} />
                    <Route path="recibo/:id" element={<AppRoute><Recibo /></AppRoute>} />
                    <Route path="contratos" element={<AppRoute isPrivate={true}><Contratos /></AppRoute>} />
                    <Route path="contrato/:id" element={<AppRoute isPrivate={true}><Contrato /></AppRoute>} />
                    <Route path="clientes" element={<AppRoute isPrivate={true}><Clientes /></AppRoute>} />
                    <Route path="cliente/:id" element={<AppRoute isPrivate={true} ><Cliente /></AppRoute>} />
                    <Route path="servicios" element={<AppRoute isPrivate={true} rol='ADMIN'><Servicios /></AppRoute>} />
                    <Route path="servicio/:id" element={<AppRoute isPrivate={true} rol='ADMIN'><Servicio /></AppRoute>} />
                    <Route path="usuarios" element={<AppRoute isPrivate={true} rol='ADMIN'><Usuarios /></AppRoute>} />
                    <Route path="usuario/:id" element={<AppRoute isPrivate={true} rol='ADMIN'><Usuario /></AppRoute>} />
                    <Route path="configuracion" element={<AppRoute isPrivate={true}><Configuracion /></AppRoute>} />
                </Route>
                <Route path="403" element={<AppRoute><Error403 /></AppRoute>} />
                <Route path="*" element={<AppRoute><NotFound /></AppRoute>} />
            </Routes>
        </HashRouter>
    );
}

export default AppRoutes;