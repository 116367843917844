import * as yup from 'yup';

const UsuarioFormSchema = yup.object().shape({
    nombre: yup.string()
        .min(2, 'Mínimo dos caracteres')
        .required('Campo obligatorio'),
    apellidos: yup.string()
        .min(2, 'Mínimo dos caracteres')
        .required('Campo obligatorio'),
	email: yup.string()
		.email('Debe introducir un email válido')
		.required('Campo obligatorio'),
    password: yup.string()
        .min(6, 'Mínimo dos caracteres'),
    telefono: yup.string()
        .matches(/[0-9]+/, 'Sólo números')
        .min(9, 'Mínimo nueve caracteres'),
    cargo: yup.string()
        .min(2, 'Mínimo dos caracteres'),
    roles: yup.array()
    .of(
      yup.object().shape({
        name: yup.string(),
        label: yup.string()
      })
    ),
    comentario: yup.string()
		.min(2, 'Mínimo dos caracteres'),
    activo: yup.boolean(),
});

export default UsuarioFormSchema;