import React from 'react';
import { IMAGES_HOST } from "../../utils/constants";
import imgHombre from '../../assets/images/avatar_hombre_cliente.png';
import imgMujer from '../../assets/images/avatar_mujer_cliente.png';



const AvatarGenero = ({ cliente, tipo, className }) => {

    let imagenPerfil = cliente?.fotoPerfil;

    if (imagenPerfil) {
        if (tipo) imagenPerfil = imagenPerfil+"?tp="+tipo;
        return (
            <img 
            src={`${IMAGES_HOST}/resource/image/${imagenPerfil}`}
            onError={(e) => e.target.src = cliente.genero==='HOMBRE' ? imgHombre : imgMujer} 
            className={className} />
        );
    }

    if (cliente?.genero === 'MUJER') {
        return (
            <img src={imgMujer} className={className} />
        );
    }

    return (
        <img src={imgHombre} className={className} />
    );

}

export default AvatarGenero;