import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

// PrimeReact imports
//import "primereact/resources/themes/lara-light-teal/theme.css"; // primetheme
import "./theme.css";
import "primereact/resources/primereact.min.css"; // core css
import "primeicons/primeicons.css"; // primeicons
import "primeflex/primeflex.css"; // primeflex
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
      <App />
  </React.StrictMode>
);
