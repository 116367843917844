import React, { useRef, useState } from 'react';
import { Outlet } from "react-router-dom";

import { classNames } from 'primereact/utils';

import AppTopbar from './AppTopbar';
import PrimeReact from 'primereact/api';

import GroupTitle from "../components/Dashboard/GroupTitle";
import Section from "../components/Dashboard/Section";
import VisiblePorRol from '../components/Auth/VisiblePorRol';

import "./layoutMail.css";

const LayoutMain = () => {

  const [desktopMenuActive, setDesktopMenuActive] = useState(true);
  const [mobileMenuActive, setMobileMenuActive] = useState(false);
  const [activeTopbarItem, setActiveTopbarItem] = useState(null);
  const [mobileTopbarActive, setMobileTopbarActive] = useState(false);
  const [inlineMenuActive, setInlineMenuActive] = useState({});
  const [searchActive, setSearchActive] = useState(false);
  let currentInlineMenuKey = useRef(null);

  PrimeReact.ripple = true;

  let searchClick;
  let topbarItemClick;
  let menuClick;
  let inlineMenuClick;

  const onMenuClick = (event) => {
    menuClick = true;
  };

  const onMenuButtonClick = (event) => {
    menuClick = true;

    if (isDesktop()) setDesktopMenuActive((prevState) => !prevState);
    else setMobileMenuActive((prevState) => !prevState);

    event.preventDefault();
  };

  const onTopbarItemClick = (event) => {
    topbarItemClick = true;
    if (activeTopbarItem === event.item) setActiveTopbarItem(null);
    else {
      setActiveTopbarItem(event.item);
    }

    event.originalEvent.preventDefault();
  };

  const onSearch = (event) => {
    searchClick = true;
    setSearchActive(event);
  };

  const onMobileTopbarButtonClick = (event) => {
    setMobileTopbarActive((prevState) => !prevState);
    event.preventDefault();
  };

  const onDocumentClick = (event) => {
    if (!searchClick && event.target.localName !== 'input') {
      setSearchActive(false);
    }

    if (!topbarItemClick) {
      setActiveTopbarItem(null);
    }

    if (!menuClick && !isDesktop()) {

      hideOverlayMenu();
    }

    if (inlineMenuActive[currentInlineMenuKey.current] && !inlineMenuClick) {
      let menuKeys = { ...inlineMenuActive };
      menuKeys[currentInlineMenuKey.current] = false;
      setInlineMenuActive(menuKeys);
    }

    searchClick = false;
    topbarItemClick = false;
    inlineMenuClick = false;
    menuClick = false;
  };

  const hideOverlayMenu = () => {
    setMobileMenuActive(false);
    setDesktopMenuActive(false);
  };

  const isDesktop = () => {
    return window.innerWidth > 1024;
  };


  const layoutContainerClassName = classNames('layout-wrapper', 'layout-menu-light', 'layout-topbar-blue', 'layout-menu-static', 'p-input-filled', {
    'layout-menu-active': desktopMenuActive,
    'layout-menu-mobile-active': mobileMenuActive,
    'layout-topbar-mobile-active': mobileTopbarActive,
  });

  return (
    <div className={layoutContainerClassName} onClick={onDocumentClick}>

      <AppTopbar
        activeTopbarItem={activeTopbarItem}
        onMenuButtonClick={onMenuButtonClick}
        onTopbarItemClick={onTopbarItemClick}
        onMobileTopbarButtonClick={onMobileTopbarButtonClick}
        mobileTopbarActive={mobileTopbarActive}
        searchActive={searchActive}
        onSearch={onSearch}
      />

      <div className="menu-wrapper" onClick={onMenuClick}>
        <div className="layout-menu-container">
          <div className="overflow-y-auto">

            <ul className="list-none p-3 m-0">
              <li>
                <GroupTitle titulo="PRINCIPAL" />
                <ul className="list-none p-0 m-0 overflow-hidden">
                  <Section title={"Dashboard"} link="dashboard" icon={"pi-home"} />

                  <Section title="Contratos" link="contratos" icon="pi-briefcase" />
                  <Section title="Recibos" link="recibos" icon="pi-money-bill" />
                  <Section title="Clientes" link="clientes" icon="pi-id-card" />
                </ul>
              </li>
            </ul>

            <ul className="list-none p-3 m-0">
              <li>
                <GroupTitle titulo="ADMINISTRACIÓN" />
                <ul className="list-none p-0 m-0 overflow-hidden">
                <VisiblePorRol rol="ROLE_ADMIN">
                  <Section title="Usuarios" link="usuarios" icon="pi-users" />
                  <Section title="Servicios" link="servicios" icon="pi-sitemap" />
                </VisiblePorRol>
                  <Section
                    title={"Configuración"}
                    link="configuracion"
                    icon={"pi-cog"}
                  />
                </ul>
              </li>
            </ul>
          </div>

        </div>
      </div>

      <div className="layout-main">

        <div className="layout-content">
          <Outlet />
        </div>

      </div>

      {mobileMenuActive && <div className="layout-mask modal-in"></div>}
    </div>
  );
};

export default LayoutMain;
