import React from 'react'

const filtersAppInit = 
  {
      cliente:  {
          global : '',
          page : 0,
          activo: true
      },
      contrato: {
          global : '',
          cliente: null,
          fechaVencimiento : '',
          page: 0,
          activo: true
      },
      recibo: {
        global : '',
        cliente: null,
        fecha : '',
        pagado: false,
        page: 0,
    },
      servicio: {
        global : '',
        activo: true,
        page: 0,
    }
  }

export const FilterContext = React.createContext();

export const useFilterState = () => {
    const context = React.useContext(FilterContext);
    if (context === undefined) {
        throw new Error("useFilterState debe usarse dentro de un FilterrProvider");
    }

    return context;
}




export const FilterProvider = ({ children }) => {

    const [filtersApp, setFiltersApp] = React.useState(filtersAppInit);

    const setFiltro = (filtro) => {
        setFiltersApp({...filtersApp, ...filtro});
    }

    return (
        <FilterContext.Provider value={{ filtersApp, 
                                         setFiltersApp,
                                         setFiltro,
                                         }}>
            {children}
        </FilterContext.Provider>
    );

}