import { useNavigate } from "react-router-dom";

const useIdTemplate = (url) => {
    const navigate = useNavigate();
    let baseUrl = '';

    if (url !== null) {
        baseUrl = url.slice();
        if (!baseUrl.endsWith('/')) baseUrl = baseUrl + '/';
    }

    return (data) => {
        const onClick = (url !== null ? () => navigate(`${baseUrl}${data.id}`) : null);

        return (
          <button
            onClick={onClick}
            className={`bg-yellow-100 border-round text-center border-1 border-yellow-500 w-3rem py-1 ${url !== null ? 'cursor-pointer hover:bg-yellow-500' : ''} transition-duration-200`}
            >
            {data.id}
          </button>
        );
    }
};

export default useIdTemplate;