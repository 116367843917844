import { useNavigate } from "react-router-dom";

import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { ProgressSpinner } from 'primereact/progressspinner';


import VisiblePorRol from '../../components/Auth/VisiblePorRol';
import UsuarioForm from '../Usuario/Form/UsuarioForm';

import Card from "../../components/Cards/Card";
import useUsuarios from './useUsuarios';

const activoTemplate = (data) => {
  return (
    data.activo === true 
      ?
      <i className="pi text-green-500 pi-check-circle"></i>
      :
      <i className="pi text-pink-500 pi-times-circle"></i>
      )
}

const rolTemplate = (data) => {
  return data.roles.map(item => '['+item.name+']') 
}


const Usuarios = () => {
  const navigate = useNavigate();

  const { cargando, creando, dejarDeCrear, nuevoUsuario, usuarios } = useUsuarios();

  if (cargando === true) {
    return (
      <div className="flex h-full align-items-center ">
        <ProgressSpinner/>
      </div>
    );
  }

  return (
    <Card>
        {creando === false
        ? <>

        <div className="mb-3 flex align-items-center justify-content-between">
          <h1 className='text-2xl'>Usuarios</h1>

            <VisiblePorRol rol="ADMIN">
              <Button
              icon="pi pi-plus"
              label="Nuevo Usuario"
              className="p-button-outlined mt-2 md:mt-0"
              onClick={nuevoUsuario}
            />
            </VisiblePorRol>
        </div>

        <DataTable
          value={usuarios}
          responsiveLayout="scroll"
          size="large"
          scrollHeight="70vh"
          rowClassName={"hover:bg-blue-50 cursor-pointer"}
          onRowClick={({ data }) => {
            navigate(`/usuario/${data.id}`);
          }}
        >
      
          <Column sortable field="nombre" header="Nombre" />
          <Column sortable field="apellidos" header="Apellidos" />
          <Column sortable field="email" header="Email" />
          <Column field="rol" header="roles" body={rolTemplate} />
          <Column sortable header="Activo" body={activoTemplate} align="center" headerStyle={{width: '3em'}}/>

        </DataTable>
        </>
        : <UsuarioForm usuario={null}  onCerrar={dejarDeCrear} />
      }
      </Card>
  );
};

export default Usuarios;