import React from 'react'
import { Button } from "primereact/button";


export default function Paginador(
    {
        nombre,
        totalItems,
        numPagina,
        totalPaginas,
        onAnterior,
        onSiguiente,
        debug }
) {


    const _onSiguiente = () => {
        if ((numPagina+1) < (totalPaginas)) {
            onSiguiente();
        }
    }

    const _onAnterior = () => {
        if ((numPagina+1)> 0) {
            onAnterior();
        }
    }


    return (
        <>
            { debug ?
            <ul>
                <li>nombre:{nombre}</li>
                <li>totalItems:{totalItems}</li>
                <li>numPagina:{numPagina+1}</li>
                <li>totalPaginas:{totalPaginas}</li>
            </ul>
            : null }
            <div className="p-paginator p-component p-paginator-bottom justify-content-end">

                <span style={{ color: 'var(--text-color)', userSelect: 'none', textAlign: 'center' }}>
                    {/* {(items?.length*(1+filtroItems.page)-30)+1} - {items?.length*(1+filtroItems.page)} de {totalItems} */}
                    Listando  {totalItems} {nombre}  {totalPaginas > 1 ? <> / Página {numPagina+1} de {totalPaginas}</> : null }
                </span>

                {totalPaginas > 1 ?
                <span className="ml-2">
                <Button
                    icon="pi pi-angle-left"
                    className="p-button-rounded p-button-text"
                    aria-label="Anterior"
                    disabled={(numPagina+1) === 1}
                    onClick={_onAnterior} />

                <Button
                    icon="pi pi-angle-right"
                    className="p-button-rounded p-button-text"
                    aria-label="Siguiente"
                    disabled={(numPagina+1) === totalPaginas}
                    onClick={_onSiguiente} />
                </span> : null}

            </div>
        </>
    )
}
