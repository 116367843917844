import { useRef, useState, useContext } from 'react';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import { Messages } from 'primereact/messages';
import { useFormik } from 'formik';
import ReciboFormSchema from './ReciboFormSchema';
import { ApiConstants, postApi } from '../../../api';
import {UserContext} from '../../../context/UserContext';

let mensajes;

const ReciboForm = ({ recibo, onCerrar, onUpdate }) => {

    //alert(JSON.stringify(recibo, null, 2));

    const { userState } = useContext(UserContext);

    mensajes = useRef(null);

    // const [pvp, setPvP] = useState(contrato?.servicio?.pvp || 0);
    // const { servicios } = useServicios();
    // const { clientes, filtroClientes, setFiltroClientes } = useClientes({ id: contrato?.cliente?.id });
    const [isSubmitting, setSubmiting] = useState(false);

    // const onFiltroCliente = (event) => {
    //     let _filterValue = event.filter;
    //     setFiltroClientes({ ...filtroClientes, global: _filterValue });
    // }

    // const onServicioChange = (e) => {
    //     let servicio = servicios.find(element => element.id === e.value);
    //     if (servicio) {
    //         setPvP(servicio?.pvp);
    //         formik.setValues({ ...formik.values, periodicidad: servicio.periodicidad });
    //     }
    //     formik.handleChange(e);
    // }

    const formik = useFormik({
        initialValues: {
             id: recibo?.id || '',
             importe: recibo?.importe || '',
             detalle: recibo?.detalle || '',
             observaciones: recibo?.observaciones || '',
             pagado: recibo?.pagado || false,
             activo: recibo?.activo || false
        },
        validationSchema: ReciboFormSchema,
        onSubmit: values => {
            setSubmiting(true);
            //alert(JSON.stringify(values, null, 2));
            //values = { ...values, servicio: { id: values.servicio }, cliente: { id: values.cliente } };
            if (recibo !== null) {
                postApi(ApiConstants.RECIBOS.UPDATE, userState.user.token, values, null).then(response => {
                    onUpdate(response.data.data);
                    onCerrar();
                });
            } else {
                postApi(ApiConstants.RECIBOS.CREATE, userState.user.token, values, null).then(response => {
                    onUpdate();
                    onCerrar();
                });
            }

            setSubmiting(false);
        },
    });


    return (
        <>

            <form onSubmit={formik.handleSubmit}>

                <div className="surface-section px-4 py-8 md:px-6 lg:px-8">
                    <Messages ref={mensajes} />

                    <div className="grid">
                        <div className="col-12 lg:col-10">
                            <div className="grid formgrid p-fluid">
                                <div className="field mb-4 col-6">
                                    <label htmlFor="servicio" className="block">Cliente</label>
                                    <InputText name="servicio" disabled value={recibo.contrato?.cliente?.nombreCompleto} />
                                </div>

                                <div className="field mb-4 col-6">
                                    <label htmlFor="detalle" className="block">Detalle</label>
                                    <InputText name="detalle" value={formik.values.detalle}  onChange={formik.handleChange} placeholder="Detalle del recibo"  />
                                </div>

                                <div className="field mb-4 col-6">
                                    <label htmlFor="importe" className="block">Importe</label>
                                    <div className="p-inputgroup">
                                        <InputText className="text-right" name="importe" value={formik.values.importe}  onChange={formik.handleChange} placeholder="Importe" />
                                        <span className="p-inputgroup-addon">€</span>
                                    </div>
                                </div>

                                <div className="field mb-4 col-6">
                                <label className="block">Estado</label>
                                    <Checkbox
                                        name="pagado"
                                        onChange={formik.handleChange}
                                        checked={formik.values.pagado}
                                    />
                                    <span className="ml-2 text-900">Pagado</span>
                                </div>


                                <div className="field mb-4 col-12">
                                    <Checkbox
                                        name="activo"
                                        onChange={formik.handleChange}
                                        checked={formik.values.activo}
                                    />
                                    <span className="ml-2 text-900">Activo</span>
                                </div>

                                <div className="field mb-4 col-12">
                                <label htmlFor="observaciones" className="block">Observaciones</label>
                                <InputTextarea 
                                            name="observaciones" 
                                            value={formik.values.observaciones}
                                            onChange={formik.handleChange}
                                            className={formik.errors.observaciones && formik.touched.observaciones ? 'p-invalid' : null}                                    
                                            />
                                               {formik.errors.observaciones && formik.touched.observaciones ? (
                                                <small className="p-error block">{formik.errors.observaciones}</small>
                                            ) : null}
                               
                                </div>

                                <div className="col-12">
                                    <Button type="submit" disabled={isSubmitting} label={isSubmitting ? 'Enviando' : 'Guardar cambios'} className="w-auto mt-3" />
                                    <Button label="Cerrar" type="button" className="w-auto mt-3 ml-3 p-button-secondary" onClick={onCerrar} />
                                </div>
                            </div>
                        </div>
                    </div> 
                   

                </div>
            </form>
        </>
    )
}


export default ReciboForm;