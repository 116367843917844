import React from "react";

import { Button } from "primereact/button";
import Card from "../components/Cards/Card";
import { useUserState } from '../context/UserContext';

const Profile = () => {

  const { userState } = useUserState();
  const { nombre, apellidos, email, roles, token, version } = userState.user;

  return (
    <Card>
      <div className="font-medium text-3xl text-900 mb-3">Perfil usuario</div>
      <div className="grid grid-nogutter border-top-1 surface-border pt-2">
        <div className="col-12 md:col-6 p-3">
          <div className="text-500 font-medium mb-2">Nombre</div>
          <div className="text-900">{nombre} {apellidos}</div>
        </div>
        <div className="col-12 md:col-6 p-3">
          <div className="text-500 font-medium mb-2">Email</div>
          <div className="text-900">{email}</div>
        </div>
        <div className="col-12 md:col-6 p-3">
          <div className="text-500 font-medium mb-2">Roles</div>
          <div className="text-900">{roles.toString()}</div>
        </div>
        <div className="col-12 md:col-6 p-3">
          <div className="text-500 font-medium mb-2">Versión</div>
          <div className="text-900">{version}</div>
        </div>
        <div className="col-12 p-3">
          <div className="text-500 font-medium mb-2">token</div>
          <div className="text-900 line-height-3">
          {token}
          </div>
        </div>
        <div className="col-12 p-3">
          <div className="text-500 font-medium mb-3">Files</div>
          <div className="flex md:align-items-center md:justify-content-between border-top-1 surface-border p-3 flex-column md:flex-row">
            <div className="flex align-items-center">
              <span className="block pi pi-file mr-2"></span>
              <span className="text-900">resumen.pdf</span>
            </div>
            <Button
              icon="pi pi-download"
              label="Download"
              className="p-button-outlined mt-2 md:mt-0"
            />
          </div>
          <div className="flex md:align-items-center md:justify-content-between border-top-1 border-bottom-1 surface-border p-3 flex-column md:flex-row">
            <div className="flex align-items-center">
              <span className="block pi pi-file mr-2"></span>
              <span className="text-900">protesis-423.pdf</span>
            </div>
            <Button
              icon="pi pi-download"
              label="Download"
              className="p-button-outlined mt-2 md:mt-0"
            />
          </div>
        </div>
      </div>
    </Card>
  );
};

export default Profile;
