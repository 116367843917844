import { useState, useEffect } from 'react';

import { ApiConstants, postApi } from '../../api';
import { useUserState } from '../../context/UserContext';

const usePaises = (filtro) => {
    const { userState } = useUserState();
    const [paises, setPaises] = useState([]);
    const [cargando, setCargando] = useState(true);
    const [creando, setCreando] = useState(false);

    const [status, setStatus] = useState(0);
    const [statusText, setStatusText] = useState('');
    const [totalItems, setTotalItems] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [filtroPaises, setFiltroPaises] = useState(filtro);


    const nuevoPais = () => {
        setCreando(true);
    }

    const dejarDeCrear = () => {
        setCreando(false);
    }

    const loadPaises = () => {
        postApi(ApiConstants.PAISES.LISTA, userState.user.token, filtroPaises)
        .then(response => {
        if (response){
          setPaises([...response.data.data]);
          
          setTotalItems(response.data.totalItems);
          setTotalPages(response.data.totalPages);
          setCurrentPage(response.data.currentPage);
          setStatus(response.data.status);
          setStatusText(response.data.statusText);
          
          setCargando(false);
        }
        });
    }

    useEffect(() => {
        loadPaises();
    }, [filtroPaises, userState.user.token]);
    

    return {
        cargando,
        creando,
        dejarDeCrear,
        loadPaises,
        nuevoPais,
        totalItems,
        totalPages,
        currentPage,
        status,
        statusText,
        setFiltroPaises,
        paises
    }

};

export default usePaises;