import React from 'react';
import { useNavigate } from "react-router-dom";
import { Toolbar } from 'primereact/toolbar';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from 'primereact/inputtext';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Button } from "primereact/button";
import { Checkbox } from 'primereact/checkbox';

import Card from "../../components/Cards/Card";
import Paginador from '../../components/Paginador/Paginador';
import useClientes from './useClientes';
import ClienteForm from '../Cliente/Form/ClienteForm';
import { IMAGES_HOST } from "../../utils/constants";
import imgHombre from '../../assets/images/avatar_hombre_cliente.png';
import imgMujer from '../../assets/images/avatar_mujer_cliente.png';

let navigate;

const activoTemplate = (data) => {
  return (
    data.activo === true
      ?
      <i className="pi text-green-500 pi-check-circle"></i>
      :
      <i className="pi text-pink-500 pi-times-circle"></i>
  )
}

const styleAvatar = { verticalAlign: 'middle',
                      width: '40px',  
                      height: '40px',  
                      borderRadius: '50%',
                      objectFit: 'cover',
                      objectPosition: 'top'
};

const nombreConFoto = (data) => {

  let imagenPerfil = data?.fotoPerfil+"?tp=thumb";

  return (
      <React.Fragment>
         {data.fotoPerfil && data.fotoPerfil.length>0 ?
          <img 
            alt={data.nombre} 
            src={`${IMAGES_HOST}/resource/image/${imagenPerfil}`} 
            onError={(e) => e.target.src = data.genero==='HOMBRE' ? imgHombre : imgMujer} 
            style={styleAvatar} 
            className='mr-2'
            />
          :
          <img 
          alt={data.nombre} 
          src={data.genero==='HOMBRE' ? imgHombre : imgMujer}
          style={styleAvatar} 
          className='mr-2'
          />
      } 
        <span className="image-text">{data.nombre}</span>
      </React.Fragment>
  );
}


const Clientes = ({ filtro }) => {
  navigate = useNavigate();

  const { 
    cargando, 
    totalItems, 
    totalPages, 
    creando, 
    dejarDeCrear, 
    loadItems, 
    nuevoItem, 
    items, 
    filtroItems,
    setFiltroState
  }
    = useClientes(filtro);


  const onSiguiente = () => {
      setFiltroState({...filtroItems, page: (filtroItems.page)+1});
  }

  const onAnterior = () => {
      setFiltroState({...filtroItems, page: (filtroItems.page)-1});
  }

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    if (filtroItems.global!==value){
      setFiltroState({...filtroItems, global: value, page: 0});
    }
  }

  const onChangueSoloActivo= (e) => {
    const valueCode = e.target.checked;
    let valueSoloActivo = undefined;
    if (valueCode === true) {
      valueSoloActivo = true;
    }

    if (filtroItems.activo !== valueSoloActivo) {
       setFiltroState({ ...filtroItems, page:0, activo: valueSoloActivo });
    }
   }


  const leftToolBar = (
    <React.Fragment>
      <span className="p-input-icon-left mr-2">
        <i className="pi pi-search" />
        <InputText
          value={filtroItems.global}
          onChange={onGlobalFilterChange} 
          placeholder="Buscar..." />
      </span>
      <Checkbox 
      inputId="soloActivos"
      onChange={onChangueSoloActivo} 
      checked={filtroItems.activo}
      className="mr-2">
      </Checkbox>
      <label htmlFor="soloActivos" className="p-checkbox-label">Sólo Activos</label>
    </React.Fragment>
  );

  const rightToolBar = (
    <React.Fragment>
      <Button
        icon="pi pi-plus"
        label="Nuevo Cliente"
        className="p-button-outlined mt-2 md:mt-0"
        onClick={nuevoItem}
      />
    </React.Fragment>
  );

  
  if (cargando === true) {
    return (
      <div className="flex h-full align-items-center ">
        <ProgressSpinner/>
      </div>
    );
  }



  return (

    <Card>

      {creando === false
        ? <>
          <h1 className='text-2xl'>Clientes</h1>

          <Toolbar left={leftToolBar} right={rightToolBar} />

          <DataTable
            value={items}
            lazy
            responsiveLayout="scroll"
            onRowClick={(e) => {
              const { id } = e.data;
              navigate(`/cliente/${id}`);
            }}

            rowClassName={"hover:bg-blue-50 cursor-pointer"}
          >

            <Column field="nombre" header="Nombre" body={nombreConFoto}/>
            <Column field="apellidos" header="Apellidos" />
            <Column field="email" header="Email" />
            <Column field="telefono" header="Teléfono" />

            <Column header="Activo" body={activoTemplate} align="center" headerStyle={{ width: '3em' }} />
          </DataTable>

          <Paginador 
            nombre="clientes" 
            totalItems={totalItems}
            numPagina={filtroItems?.page}
            totalPaginas={totalPages}
            onAnterior={onAnterior}
            onSiguiente={onSiguiente}
            />
        </>
        :
        <ClienteForm cliente={null} onUpdate={loadItems} onCerrar={dejarDeCrear} />
      }
    </Card>
  );

};

export default Clientes;