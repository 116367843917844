import { NavLink } from "react-router-dom";
import { Ripple } from "primereact/ripple";

const Section = ({ title, link, icon }) => {
  const defaultStyle =
    "no-underline p-ripple flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-300 transition-colors w-full hover:bg-blue-200 my-1";

  return (
    <li>
      <NavLink
        to={`${link}`}
        className={({ isActive }) =>
          isActive ? `${defaultStyle} bg-blue-100` : `${defaultStyle}`
        }
      >
        <i className={`pi ${icon} mr-2`}></i>
        <span className="font-medium">{title}</span>
        <Ripple />
      </NavLink>
    </li>
  );
};

export default Section;
