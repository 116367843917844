import React, { useRef } from 'react';
import { classNames } from 'primereact/utils';
import { useNavigate } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { CSSTransition } from 'react-transition-group';
import logo from "../assets/images/logo.png";
import avatar from "../assets/images/avatar.png";
import { useUserState } from '../context/UserContext';

const AppTopbar = (props) => {
    const navigate = useNavigate();
    const {userState, logout} = useUserState();

    const topbarRef1 = useRef(null);

    const topbarRef4 = useRef(null);

    // Fixed for 6.1.0
    // eslint-disable-next-line
    const searchPanel = useRef(null);



    const onInputKeydown = (event) => {
        const key = event.which;

        //escape, tab and enter
        if (key === 27 || key === 9 || key === 13) {
            props.onSearch(false);
        }
    };



    return (
        <div className="layout-topbar shadow-4">
            <div className="layout-topbar-left">
                <button type="button" style={{ cursor: 'pointer' }} className="layout-topbar-logo p-link" onClick={() => navigate('/')}>
                    <img id="app-logo" src={logo} alt="NaturGym" style={{ height: '50px' }} />
                </button>
                <button type="button" className="layout-menu-button shadow-6 p-link" onClick={props.onMenuButtonClick}>
                    <i className="pi pi-chevron-right"></i>
                </button>
                <button type="button" className="layout-topbar-mobile-button p-link">
                    <i className="pi pi-ellipsis-v fs-large" onClick={props.onMobileTopbarButtonClick}></i>
                </button>
            </div>

            <div className={classNames('layout-topbar-right', { 'layout-topbar-mobile-active': props.mobileTopbarActive })}>
                <div className="layout-topbar-actions-left">
                </div>


                
                <div className="layout-topbar-actions-right">
                    <ul className="layout-topbar-items">
                    <li className="layout-topbar-item layout-search-item">{userState.user.nombre}</li>
                        {/* <li className="layout-topbar-item layout-search-item">
                            <button className="layout-topbar-action rounded-circle p-link" onClick={() => props.onSearch(true)}>
                                <i className="pi pi-search fs-large"></i>
                            </button>
                            <CSSTransition nodeRef={topbarRef1} classNames="p-toggleable" timeout={{ enter: 1000, exit: 450 }} in={props.searchActive} unmountOnExit>
                                <div ref={topbarRef1} className="layout-search-panel p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-search"></i>
                                    </span>
                                    <InputText type="text" placeholder="Buscar" onKeyDown={onInputKeydown} />
                                    <span className="p-inputgroup-addon">
                                        <Button type="button" icon="pi pi-times" className="p-button-rounded p-button-text p-button-plain" onClick={() => props.onSearch(false)}></Button>
                                    </span>
                                </div>
                            </CSSTransition>
                        </li> */}





                        <li className="layout-topbar-item">
                            <button className="layout-topbar-action flex flex-row justify-content-center align-items-center px-2 rounded-circle p-link" onClick={(event) => props.onTopbarItemClick({ originalEvent: event, item: 'profile' })}>
                                <img src={avatar} alt="avatar" style={{ width: '32px', height: '32px' }} />
                            </button>

                            <CSSTransition nodeRef={topbarRef4} classNames="p-toggleable" timeout={{ enter: 1000, exit: 450 }} in={props.activeTopbarItem === 'profile'} unmountOnExit>
                                <ul ref={topbarRef4} className="layout-topbar-action-panel shadow-6">
                                    {/* <li className="layout-topbar-action-item">
                                        <button className="flex flex-row align-items-center p-link">
                                            <i className={classNames('pi pi-cog',  'mr-2')}></i>
                                            <span>Settings</span>
                                        </button>
                                    </li>
                                    <li className="layout-topbar-action-item">
                                        <button className="flex flex-row align-items-center p-link">
                                            <i className={classNames('pi pi-file',  'mr-2')}></i>
                                            <span>Terms of Usage</span>
                                        </button>
                                    </li>
                                    <li className="layout-topbar-action-item ">
                                        <button className="flex flex-row align-items-center p-link">
                                            <i className={classNames('pi pi-compass',  'mr-2')}></i>
                                            <span>Support</span>
                                        </button>
                                    </li> */}
                                    <li className="layout-topbar-action-item">
                                        <button className="flex flex-row align-items-center p-link"   onClick={ logout }>
                                            <i className={classNames('pi pi-power-off',  'mr-2')}></i>
                                            <span>Cerrar Sesión</span>
                                        </button>
                                    </li>
                                </ul>
                            </CSSTransition>
                        </li>
                   
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default AppTopbar;
