import { useRef } from "react";
import { StyleClass } from "primereact/styleclass";
import { Ripple } from "primereact/ripple";

const GroupTitle = ({ titulo }) => {
  const btn = useRef(null);
  return (
    <StyleClass
      nodeRef={btn}
      selector="@next"
      enterClassName="hidden"
      enterActiveClassName="slidedown"
      leaveToClassName="hidden"
      leaveActiveClassName="slideup"
    >
      <div
        ref={btn}
        className="p-ripple p-3 flex align-items-center justify-content-between text-600 cursor-pointer"
      >
        <span className="font-medium">{titulo}</span>
        <i className="pi pi-chevron-down"></i>
        <Ripple />
      </div>
    </StyleClass>
  );
};

export default GroupTitle;
