import { useRef, useState, useContext } from 'react';
import { Toast } from 'primereact/toast';
import { FileUpload } from 'primereact/fileupload';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Messages } from 'primereact/messages';
import { useFormik } from 'formik';
import ClienteFormSchema from './ClienteFormSchema';
import { ApiConstants, postApi } from '../../../api';
import usePaises from '../usePaises';
import useProvincias from '../useProvincias';
import { UserContext } from '../../../context/UserContext';
import AvatarGenero from "../../../components/AvatarGenero";
import { API_HOST } from "../../../utils/constants";
import axios from "axios";
import { dameBooleano } from '../../../utils/tipos';


let mensajes;

const ClienteForm = ({ cliente, onCerrar, onUpdate }) => {

    const { userState } = useContext(UserContext);
    mensajes = useRef(null);
    const toast = useRef(null);
    const fileUploadRef = useRef(null);

    const { paises } = usePaises();
    const { provincias, filtroProvincias, setFiltroProvincias } = useProvincias({ id: cliente?.pais?.id });
    const [isSubmitting, setSubmiting] = useState(false);
    const [clientePerfil, setClientePerfil] = useState({ fotoPerfil: cliente?.fotoPerfil, genero: cliente?.genero });
    const uploadFoto = async (event) => {
        const file = event.files[0];
        const name = event.files[0].name;

        const formData = new FormData();
        formData.append("name", name);
        formData.append("foto", file);
        formData.append("cliente", cliente.id);

        const headers = {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${userState.user.token}`,
        };


        axios
            .post(`${API_HOST}/uploadFoto`, formData, { headers })
            .then((res) => {
                if (res.data.success) {
                    fileUploadRef.current.clear();
                    toast.current.show({ severity: 'info', summary: 'Success', detail: 'Fichero cargado correctamente' });
                    setClientePerfil({ ...clientePerfil, fotoPerfil: res.data.data.nombreFichero });
                }
                else {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'El fichero no se ha podido cargar' });
                }
            })
            .catch((err) => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'El fichero no se ha podido cargar:', err });
            });
    }





    //Por defecto España
    if (cliente && !cliente.pais) {
        cliente.pais = { id: 70 };
    }

    const onPaisChange = (e) => {
        let pais = paises.find(element => element.id === e.value);
        if (pais) {
            setFiltroProvincias({ ...filtroProvincias, id: pais.id });
        }

        formik.handleChange(e);
    }

    const onProvinciaChange = (e) => {
        let provincia = provincias.find(element => element.id === e.value);
        if (provincia) {
            formik.setValues({ ...formik.values, provincia: provincia.id });
        }
        else {
            formik.setValues({ ...formik.values, provincia: '' });
        }
        formik.handleChange(e);
    }


    const formik = useFormik({
        initialValues: {
            id: cliente?.id || '',
            nombre: cliente?.nombre || '',
            apellidos: cliente?.apellidos || '',
            email: cliente?.email || '',
            telefono: cliente?.telefono || '',
            direccion: cliente?.direccion || '',
            poblacion: cliente?.poblacion || '',
            cp: cliente?.cp || '',
            pais: cliente?.pais?.id || '',
            provincia: cliente?.provincia?.id || '',
            dni: cliente?.dni || '',
            genero: cliente?.genero || '',
            fechaNacimiento: cliente?.fechaNacimiento || '',
            comentario: cliente?.comentario || '',
            aceptacondiciones: dameBooleano(cliente?.aceptacondiciones,false),
            activo: dameBooleano(cliente?.activo,true)
        },
        validationSchema: ClienteFormSchema,
        onSubmit: values => {
            setSubmiting(true);
            //alert(JSON.stringify(values, null, 2));
            values = { ...values, pais: { id: values.pais }, provincia: { id: values.provincia } };
            if (cliente !== null) {
                postApi(ApiConstants.CLIENTES.UPDATE, userState.user.token, values, null).then(response => {
                    //alert(JSON.stringify(response.data.data, null, 2));
                    onUpdate(response.data.data);
                    onCerrar();
                });
            } else {
                postApi(ApiConstants.CLIENTES.CREATE, userState.user.token, values, null).then(response => {
                    onUpdate();
                    onCerrar();
                });
            }

            setSubmiting(false);
        },
    });

    return (
        <>
            <Toast ref={toast}></Toast>

            <form onSubmit={formik.handleSubmit}>
                <div className="surface-section px-4 py-4">
                    <h1 className='text-2xl'>Edición de cliente</h1>

                    <Messages ref={mensajes} />

                    <div className="grid">

                        <div className="col-9">
                            <div className="grid formgrid p-fluid">

                                <div className="field mb-4 col-12">
                                    <Checkbox
                                        name="activo"
                                        onChange={formik.handleChange}
                                        checked={formik.values.activo} />
                                    <span className="ml-2 text-900">Activo</span>

                                </div>

                                <div className="field mb-4 col-6">
                                    <label htmlFor="nombre" className="block">Nombre</label>
                                    <InputText
                                        name="nombre"
                                        value={formik.values.nombre}
                                        onChange={formik.handleChange}
                                        placeholder="Nombre"
                                        className={formik.errors.nombre && formik.touched.nombre ? 'p-invalid' : null}
                                    />
                                    {formik.errors.nombre && formik.touched.nombre ? (
                                        <small className="p-error block">{formik.errors.nombre}</small>
                                    ) : null}
                                </div>

                                <div className="field mb-4 col-6">
                                    <label htmlFor="apellidos" className="block">Apellidos</label>
                                    <InputText
                                        name="apellidos"
                                        value={formik.values.apellidos}
                                        onChange={formik.handleChange}
                                        placeholder="Apellidos"
                                        className={formik.errors.apellidos && formik.touched.apellidos ? 'p-invalid' : null}
                                    />
                                    {formik.errors.apellidos && formik.touched.apellidos ? (
                                        <small className="p-error block">{formik.errors.apellidos}</small>
                                    ) : null}
                                </div>
                                <div className="field mb-4 col-6">
                                    <label htmlFor="email" className="block">Email</label>
                                    <InputText
                                        name="email"
                                        value={formik.values.email}
                                        onChange={formik.handleChange}
                                        placeholder="Email"
                                        className={formik.errors.email && formik.touched.email ? 'p-invalid' : null}
                                    />
                                    {formik.errors.email && formik.touched.email ? (
                                        <small className="p-error block">{formik.errors.email}</small>
                                    ) : null}
                                </div>


                                <div className="field mb-4 col-6">
                                    <label htmlFor="newPassword" className="block">NIF</label>

                                    <InputText
                                        name="dni"
                                        value={formik.values.dni}
                                        onChange={formik.handleChange}
                                        placeholder="NIF / DNI"
                                        className={formik.errors.dni && formik.touched.dni ? 'p-invalid' : null}
                                    />
                                    {formik.errors.dni && formik.touched.dni ? (
                                        <small className="p-error block">{formik.errors.dni}</small>
                                    ) : null}
                                </div>

                                <div className="field mb-4 col-6">
                                    <label htmlFor="telefono" className="block">Teléfono</label>
                                    <InputText
                                        name="telefono"
                                        value={formik.values.telefono}
                                        onChange={formik.handleChange}
                                        placeholder="Teléfono"
                                        className={formik.errors.telefono && formik.touched.telefono ? 'p-invalid' : null}
                                    />
                                    {formik.errors.telefono && formik.touched.telefono ? (
                                        <small className="p-error block">{formik.errors.telefono}</small>
                                    ) : null}
                                </div>


                                <div className="field mb-4 col-6">
                                    <label htmlFor="direccion" className="block">Dirección</label>
                                    <InputText
                                        name="direccion"
                                        value={formik.values.direccion}
                                        onChange={formik.handleChange}
                                        placeholder="Dirección"
                                        className={formik.errors.direccion && formik.touched.direccion ? 'p-invalid' : null}
                                    />
                                    {formik.errors.direccion && formik.touched.direccion ? (
                                        <small className="p-error block">{formik.errors.direccion}</small>
                                    ) : null}
                                </div>
                                <div className="field mb-4 col-6">
                                    <label htmlFor="cp" className="block">Código Postal</label>
                                    <InputText
                                        name="cp"
                                        value={formik.values.cp}
                                        onChange={formik.handleChange}
                                        placeholder="CP"
                                        className={formik.errors.cp && formik.touched.cp ? 'p-invalid' : null}
                                    />
                                    {formik.errors.cp && formik.touched.cp ? (
                                        <small className="p-error block">{formik.errors.cp}</small>
                                    ) : null}
                                </div>

                                <div className="field mb-4 col-6">
                                    <label htmlFor="poblacion" className="block">Población</label>
                                    <InputText
                                        name="poblacion"
                                        value={formik.values.poblacion}
                                        onChange={formik.handleChange}
                                        placeholder="Población"
                                        className={formik.errors.poblacion && formik.touched.poblacion ? 'p-invalid' : null}
                                    />
                                    {formik.errors.poblacion && formik.touched.poblacion ? (
                                        <small className="p-error block">{formik.errors.poblacion}</small>
                                    ) : null}
                                </div>

                                <div className="field mb-4 col-6">
                                    <label htmlFor="pais" className="block">Pais</label>
                                    <Dropdown
                                        name="pais"
                                        filter
                                        value={formik.values.pais}
                                        options={paises}
                                        onChange={onPaisChange}
                                        optionLabel="nombre"
                                        optionValue="id"
                                        placeholder="Selecciona un Pais"
                                        className={formik.errors.pais && formik.touched.pais ? 'p-invalid' : null}
                                    />
                                    {formik.errors.pais && formik.touched.pais ? (
                                        <small className="p-error block">{formik.errors.pais}</small>
                                    ) : null}
                                </div>

                                <div className="field mb-4 col-6">
                                    <label htmlFor="provincia" className="block">Provincia</label>
                                    <Dropdown
                                        name="provincia"
                                        filter={provincias.length > 0}
                                        filterBy="nombre"
                                        value={formik.values.provincia}
                                        options={provincias}
                                        onChange={onProvinciaChange}
                                        optionLabel="nombre"
                                        optionValue="id"
                                        placeholder="Selecciona un Provincia"
                                        className={formik.errors.provincia && formik.touched.provincia ? 'p-invalid' : null}
                                    />
                                    {formik.errors.provincia && formik.touched.provincia ? (
                                        <small className="p-error block">{formik.errors.provincia}</small>
                                    ) : null}
                                </div>

                                <div className="field mb-4 col-6">
                                    <label htmlFor="fechaNacimiento" className="block">Fecha Nacimiento</label>
                                    <Calendar name="fechaNacimiento"
                                        locale="es"
                                        dateFormat="dd/mm/yy"
                                        showIcon
                                        showButtonBar
                                        onChange={formik.handleChange}
                                        value={new Date(formik.values.fechaNacimiento)}
                                        className={formik.errors.fechaNacimiento && formik.touched.fechaNacimiento ? 'p-invalid' : null}
                                    ></Calendar>
                                    {formik.errors.fechaNacimiento && formik.touched.fechaNacimiento ? (
                                        <small className="p-error block">{formik.errors.fechaNacimiento}</small>
                                    ) : null}


                                </div>

                                <div className="field mb-4 col-6">
                                    <label htmlFor="genero" className="block">Género</label>
                                    <Dropdown
                                        name="genero"
                                        value={formik.values.genero}
                                        options={['HOMBRE', 'MUJER']}
                                        onChange={formik.handleChange}
                                        placeholder="Selecciona género"
                                        className={formik.errors.genero && formik.touched.genero ? 'p-invalid' : null}
                                    />
                                    {formik.errors.genero && formik.touched.genero ? (
                                        <small className="p-error block">{formik.errors.genero}</small>
                                    ) : null}
                                </div>

                                <div className="field mb-4 col-12">
                                    <label htmlFor="comentario" className="block">Observaciones</label>
                                    <InputTextarea
                                        name="comentario"
                                        value={formik.values.comentario}
                                        onChange={formik.handleChange}
                                        className={formik.errors.comentario && formik.touched.comentario ? 'p-invalid' : null}
                                    />
                                    {formik.errors.comentario && formik.touched.comentario ? (
                                        <small className="p-error block">{formik.errors.comentario}</small>
                                    ) : null}
                                </div>


                                <div className="field mb-4 col-12">
                                    <Checkbox
                                        name="aceptacondiciones"
                                        onChange={formik.handleChange}
                                        checked={formik.values.aceptacondiciones} />
                                    <span className="ml-2 text-900">Acepta condiciones</span>
                                </div>



                                <div className="col-12">
                                    <Button type="submit" disabled={isSubmitting} label={isSubmitting ? 'Enviando' : 'Guardar cambios'} className="w-auto mt-3" />
                                    <Button label="Cerrar" type="button" className="w-auto mt-3 ml-3 p-button-secondary" onClick={onCerrar} />
                                </div>
                            </div>
                        </div>

                        <div className="col-3 pt-2 px-2">
                            <div className="flex flex-column align-items-center">
                                <AvatarGenero
                                    cliente={clientePerfil}
                                    tipo='opt'
                                    className="max-w-full" />

                                <label htmlFor="fotoPerfil" className="block mb-2">&nbsp;</label>

                                {cliente
                                    ?
                                    <FileUpload
                                        ref={fileUploadRef}
                                        emptyTemplate={<p className="m-0">Subir una imagen de perfil.</p>} 
                                        name="fotoPerfil"
                                        accept="image/*"
                                        maxFileSize={1000000}
                                        customUpload={true}
                                        uploadHandler={uploadFoto}
                                        auto
                                        invalidFileSizeMessageSummary={'Imagen muy grande'}
                                        invalidFileSizeMessageDetail={''}

                                        chooseLabel="Subir Foto" />
                                    : null}

                            </div>
                        </div>

                    </div>

                </div>
            </form>
        </>
    )
}

export default ClienteForm;