import * as yup from 'yup';

const ReciboFormSchema = yup.object().shape({
    // cliente: yup.number()
    // .required('Seleccione un cliente'),
    // servicio: yup.number()
    // .required('seleccione un servicio'),
    // fechaVencimiento: yup.date()
    // .required('Necesita una fecha de vencimiento'),

    activo: yup.boolean(),
});

export default ReciboFormSchema;