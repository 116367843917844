import axios from "axios";
import { API_HOST } from "../utils/constants";

export async function postApi(uri,token,paramsPost, id){
  let url = new URL(`${API_HOST}/${uri}`);
  if (id) {
    url = `${API_HOST}/${uri}/${id}`;
  }
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  return axios.post(url, paramsPost, { headers });
}


export async function getApi(uri, token, paramsGet, id) {
  let url = new URL(`${API_HOST}/${uri}`);
  if (id) {
    url = `${API_HOST}/${uri}/${id}`;
  }
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  if (paramsGet){
    url.searchParams.append('page',paramsGet.page);
  } 


  return axios.get(url, { headers });
}


export async function postApiPDF(uri,token,paramsPost){
  let url = new URL(`${API_HOST}/${uri}`);

  const headers = {
    "Content-Type": "application/json",
    "Accept": "application/pdf",
    Authorization: `Bearer ${token}`,
  };

  return axios.post(url, paramsPost, { responseType: 'arraybuffer', headers });
}
