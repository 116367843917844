const ApiConstants = {
    AUTH: {
        LOGIN:  'auth/login',
        LOGOUT: 'auth/logout',
        TOKEN_VALIDATE: 'auth/tokenvalidate'
    },
    SERVICIOS: {
        LISTA: 'servicios',
        ACTIVOS: 'listaServiciosActivos',
        DATOS: 'servicio',
        UPDATE: 'updateServicio',
        CREATE: 'createServicio'
    },
    CLIENTES: {
        LISTA: 'clientes',
        ACTIVOS: 'listaClientesActivos',
        DATOS: 'cliente',
        UPDATE: 'updateCliente',
        CREATE: 'createCliente'
    },
    ESTADISTICAS: {
        GENERALES: 'estadisticaGeneral',
        FACTURACION: 'stats/fact',
        FACTURACIONOBJ: 'stats/factobj'
    },
    USUARIOS: {
        LISTA:  'usuarios',
        DATOS:  'usuario',
        UPDATE: 'updateUsuario',
        CREATE: 'createUsuario'
    },
    RECIBOS: {
        LISTA: 'recibos',
        DATOS: 'recibo',
        UPDATE: 'updateRecibo',
        CREATE: 'createRecibo',
        DELETE: 'deleteRecibo',
        IMPRIMIR_RECIBOS: 'recibospdf'

    },
    CONTRATOS: {
        LISTA: 'contratos',
        DATOS: 'contrato',
        UPDATE: 'updateContrato',
        CREATE: 'createContrato',
        GENERAR_RECIBOS: 'generarRecibosDeContratos'
    },
    PAISES: {
        LISTA: 'paises',
        DATOS: 'pais',
    },
    PROVINCIAS: {
        LISTA: 'provincias',
        DATOS: 'provincia',
    }
};

export default ApiConstants;
