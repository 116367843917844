/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate } from "react-router-dom";

import Card from "../../components/Cards/Card";
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Checkbox } from 'primereact/checkbox';

import formatFecha from '../../utils/formatFecha';
import useContrato from './useContrato';
import ContratoForm from './Form/ContratoForm';
import Recibos from '../Recibos';


const Contrato = () => {
  const navigate = useNavigate();

  const { cargando, contrato, setContrato, mostrarBotonEditar, editar, editando, noEditar } = useContrato();

  const filtroRecibos = {
    cliente: contrato?.cliente,
    activo: true
  };

  if (cargando === true) {
    return (
      <div className="flex h-full align-items-center ">
        <ProgressSpinner/>
      </div>
    );
  }



  if (!editando) {
    return (
      <>
      <Card>
        <div className="col-12">

          <div className="flex md:align-items-center md:justify-content-between surface-border p-0 flex-column md:flex-row">

            <div className="font-medium text-3xl text-900 mb-3">Contrato nº {contrato.id}
            </div>
            <div className="font-medium text-3xl text-900 mb-3">
              <div className="text-base text-500 font-medium mb-2"><br />Alta</div>
              <div className="text-base text-900">{formatFecha(contrato.fechaAlta)}</div>
            </div>
            <div className="flex justify-content-between">
              {mostrarBotonEditar
                ?
                <Button
                  icon="pi pi-user-edit"
                  label="Editar"
                  className="p-button-outlined mt-2 md:mt-0 mr-2"
                  onClick={editar}
                />
                : null
              }
              <Button
                className="p-button-rounded p-button-secondary"
                icon="pi pi-arrow-left" onClick={() => navigate(-1)} />
            </div>
          </div>
        </div>





        <div className="grid grid-nogutter border-top-1 surface-border pt-2">
                        <div className="col-2">
                            <div className="grid formgrid p-fluid">
                                  <div className="field mb-4 col-12">
                                      <Checkbox
                                          disabled={true}
                                          name="activo"
                                          checked={contrato.activo}
                                      />
                                      <span className="ml-2 text-900">Activo</span>
                                  </div>

                                  <div className="field mb-4 col-12">
                                      <Checkbox
                                          disabled={true}
                                          name="renovable"
                                          checked={contrato.renovable}
                                      />
                                      <span className="ml-2 text-900">Renovable</span>
                                  </div>
                            </div>
                        </div>

                        <div className="col-10">

                          <div className="grid grid-nogutter">
                            <div className="col-12 md:col-6 p-3">
                              <div className="text-500 font-medium mb-2">Cliente</div>
                              <div className="text-900 text-xl font-medium"><a href={`/#/cliente/${contrato.cliente?.id}`}>{contrato.cliente?.nombreCompleto}</a></div>
                            </div>
                            <div className="col-12 md:col-6 p-3">
                              <div className="text-500 font-medium mb-2">Vencimiento</div>
                              <div className="text-900">{formatFecha(contrato.fechaVencimiento)}</div>
                            </div>
                            <div className="col-12 md:col-6 p-3">
                              <div className="text-500 font-medium mb-2">Servicio</div>
                              <div className="text-900">{contrato.servicio?.nombre}</div>
                            </div>

                            <div className="col-12 md:col-6 p-3">
                              <div className="text-500 font-medium mb-2">Periodicidad</div>
                              <div className="text-900">{contrato.periodicidad} dias</div>
                            </div>

                            <div className="col-12 md:col-6 p-3">
                              <div className="text-500 font-medium mb-2">Importe</div>
                              <div className="text-900">{contrato.servicio.pvp} €</div>
                            </div>
                            <div className="col-12 md:col-6 p-3">
                              <div className="text-500 font-medium mb-2">Dto</div>
                              <div className="text-900">{contrato.dto}%</div>
                            </div>
                          </div>

                          <div className="col-12 p-3">
                          <div className="text-500 font-medium mb-2">Observaciones</div>
                              <div className="text-900">{contrato.observaciones}</div>
                          </div>
                      </div>
        </div>
      </Card>
     
       <Recibos filtro={filtroRecibos} modo="cliente" />
       </>

    );
  }

  return (
    <ContratoForm contrato={contrato} onUpdate={setContrato} onCerrar={noEditar} />
  );

};

export default Contrato;
