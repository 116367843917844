const { DateTime } = require("luxon");


const formatFecha = (fechaString) => {
    const fecha = DateTime.fromISO(fechaString).setLocale('es');

    return fecha.toLocaleString({ month: 'long', day: 'numeric', year: 'numeric' });
};


export const formatFechaCorto = (fechaString) => {
    const fecha = DateTime.fromISO(fechaString).setLocale('es').toFormat('dd/MM/yyyy');
    return fecha.toString();
};

export default formatFecha;