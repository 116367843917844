import { useNavigate } from "react-router-dom";
import { ProgressSpinner } from 'primereact/progressspinner';
import { Button } from 'primereact/button';

import formatFecha from '../../utils/formatFecha';
import Card from "../../components/Cards/Card";

import useUsuario from './useUsuario';
import UsuarioForm from './Form/UsuarioForm';



const Usuario = () => {
  const navigate = useNavigate();
  const { cargando, usuario, setUsuario, mostrarBotonEditar, editar, editando, noEditar } = useUsuario();

  console.log('USUARIO----');
  console.log('noEditar:',noEditar)
  console.log('setUsuario:',setUsuario)
  console.log('----USUARIO');

  if (cargando === true) {
    return (
      <div className="flex h-full align-items-center ">
        <ProgressSpinner/>
      </div>
    );
  }


  if (!editando){
    return (
      <Card>   
        <div className="col-12">

        <div className="flex md:align-items-center md:justify-content-between surface-border p-0 flex-column md:flex-row">
            <div className="flex align-items-center">
              <span className="block pi pi-user mr-2"></span>
              <span className="text-xl text-900">{usuario.nombreCompleto}</span>
            </div>
            <div className="flex justify-content-between">
            {mostrarBotonEditar
                      ? 
                      <Button
                      icon="pi pi-user-edit"
                      label="Editar"
                      className="p-button-outlined mt-2 md:mt-0 mr-2"
                      onClick={editar}
                    />
            : null
          }
                <Button 
                className="p-button-rounded p-button-secondary"
                icon="pi pi-arrow-left" onClick={() => navigate(-1)}/>
                </div>
          </div>
        </div>
        
        <div className="grid grid-nogutter border-top-1 surface-border pt-2">
          <div className="col-12 md:col-6 p-3">
            <div className="text-500 font-medium mb-2">Nombre</div>
            <div className="text-900">{usuario.nombre}</div>
          </div>
          <div className="col-12 md:col-6 p-3">
            <div className="text-500 font-medium mb-2">Apellidos</div>
            <div className="text-900">{usuario.apellidos}</div>
          </div>
          <div className="col-12 md:col-6 p-3">
            <div className="text-500 font-medium mb-2">Email</div>
            <div className="text-900">{usuario.email}</div>
          </div>
          <div className="col-12 md:col-6 p-3">
            <div className="text-500 font-medium mb-2">Roles</div>
            <div className="text-900">{usuario.roles.map(item => '['+item.name.replace('ROLE_',' ').toLowerCase()+']') }</div>
          </div>

          <div className="col-12 md:col-6 p-3">
            <div className="text-500 font-medium mb-2">Alta</div>
            <div className="text-900">{formatFecha(usuario.fecha)}</div>
          </div>
        </div>
      </Card>
    );
  }

  return (
    <UsuarioForm usuario={usuario} onUpdate={setUsuario} onCerrar={noEditar}/>
  );

};

export default Usuario;