import React from 'react';
import { useNavigate } from "react-router-dom";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ProgressSpinner } from 'primereact/progressspinner';
import { Button } from 'primereact/button';
import Paginador from '../../components/Paginador/Paginador';

import Card from "../../components/Cards/Card";
import useServicios from './useServicios';
import ServicioForm from '../Servicio/Form/ServicioForm';


let navigate;

const activoTemplate = (data) => {
  return (
    data.activo === true
      ?
      <i className="pi text-green-500 pi-check-circle"></i>
      :
      <i className="pi text-pink-500 pi-times-circle"></i>
  )
}



const Servicios = (filtro) => {
  navigate = useNavigate();

  const {
    cargando,
    creando,
    dejarDeCrear,
    loadItems,
    nuevoItem,
    items
  } = useServicios(filtro);


  if (cargando === true) {
    return (
      <div className="flex h-full align-items-center ">
        <ProgressSpinner />
      </div>
    );
  }



  return (
    <Card>
      {creando === false
        ? <>

          <div className="mb-3 flex align-items-center justify-content-between">
            <h1 className='text-2xl'>Servicios</h1>
            <Button
              icon="pi pi-plus"
              label="Nuevo Servicio"
              className="p-button-outlined mt-2 md:mt-0"
              onClick={nuevoItem}
            />
          </div>


          <DataTable
            value={items}
            lazy
            responsiveLayout="scroll"
            onRowClick={(e) => {
              const { id } = e.data;
              navigate(`/servicio/${id}`);
            }}

            rowClassName={"hover:bg-blue-50 cursor-pointer"}
          >

            <Column field="nombre" header="Servicio" />
            <Column field="pvp" header="PVP" align="right" headerStyle={{ width: '3em' }} />
            <Column field="periodicidad" header="Periodicidad" align="right" headerStyle={{ width: '3em' }} />
            <Column header="Activo" body={activoTemplate} align="center" headerStyle={{ width: '3em' }} />
          </DataTable>

        </>
        :
        <ServicioForm servicio={null} onUpdate={loadItems} onCerrar={dejarDeCrear} />
      }
    </Card>
  );

};

export default Servicios;
