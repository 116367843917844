import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";

import { ApiConstants, getApi } from '../../api';
import { useUserState } from '../../context/UserContext';


const useUsuario = () => {
    const { userState } = useUserState();

    const { id } = useParams();

    const [usuario, setUsuario] = useState([]);
    const [cargando, setCargando] = useState(true);
    const [editando, setEditando] = useState(false);
      
    const mostrarBotonEditar = userState.user.roles.includes('ROLE_ADMIN');
    

    const editar = () => {
        setEditando(true);
    }

    const noEditar = () => {
      setEditando(false);
    }

    useEffect(() => {
        getApi(ApiConstants.USUARIOS.DATOS, userState.user.token, null, id)
        .then(response => {
            setUsuario(response.data.data);
            setCargando(false);
        });
    }, [userState.user.token]);
  
    return {
        cargando,
        usuario,
        setUsuario,
        mostrarBotonEditar,
        editar,
        editando,
        noEditar
    }
};

export default useUsuario;