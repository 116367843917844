import * as yup from 'yup';

const ClienteFormSchema = yup.object().shape({
    nombre: yup.string()
        .min(2, 'Mínimo dos caracteres')
        .required('Campo obligatorio'),
    apellidos: yup.string()
        .min(2, 'Mínimo dos caracteres')
        .required('Campo obligatorio'),
	email: yup.string()
		.email('Debe introducir un email válido')
		.required('Campo obligatorio'),
    telefono: yup.string()
        .matches(/[0-9]+/, 'Sólo números')
        .min(9, 'Mínimo nueve caracteres')
		.required('Campo obligatorio'),
    genero: yup.string()
        .required('Campo obligatorio'),
    comentario: yup.string()
		.min(2, 'Mínimo dos caracteres'),
    activo: yup.boolean(),
});

export default ClienteFormSchema;