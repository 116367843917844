import { useRef, useState, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { MultiSelect } from 'primereact/multiselect';
import { Button } from 'primereact/button';
import { Messages } from 'primereact/messages';
import { useFormik } from 'formik';
import UsuarioFormSchema from './UsuarioFormSchema';
import { ApiConstants, postApi } from '../../../api';
import VisiblePorRol from '../../../components/Auth/VisiblePorRol';
import {UserContext} from '../../../context/UserContext';


let mensajes;
let navigate;

const UsuarioForm = ({ usuario, onCerrar, onUpdate }) => {
    navigate = useNavigate();

    const { userState } = useContext(UserContext);
    mensajes = useRef(null);

    const [isSubmitting, setSubmiting] = useState(false);

    const formik = useFormik({
        initialValues: {
            id: usuario?.id || '', 
            nombre: usuario?.nombre || '',
            apellidos: usuario?.apellidos || '',
            email: usuario?.email || '',
            telefono: usuario?.telefono || '',
            cargo: usuario?.cargo || '',
            roles: usuario?.roles || [],
            comentario: usuario?.comentario || '',
            newPassword: usuario?.newPassword || '',
            activo: usuario?.activo || false
        },
        validationSchema: UsuarioFormSchema,
        onSubmit: values => {
            setSubmiting(true);
            if (usuario !== null) {
                postApi(ApiConstants.USUARIOS.UPDATE, userState.user.token, values, null).then(response => {
                    onUpdate(response.data.data);
                    onCerrar();
                });
            } else {
                console.log('vamos a create usuario')

                postApi(ApiConstants.USUARIOS.CREATE, userState.user.token, values, null).then(response => {
                    navigate(`/usuario/${response.data.data.id}`);
                    onCerrar();
                });
            }

            setSubmiting(false);
        },
    });

    return (

        <form  onSubmit={formik.handleSubmit}>
            <div className="surface-section px-4 py-8 md:px-6 lg:px-8">
                <Messages ref={mensajes} />
             
                <div className="grid">
                    <div className="col-12 lg:col-10">
                        <div className="grid formgrid p-fluid">
                            <VisiblePorRol rol="ROLE_ADMIN">
                                <div className="field mb-4 col-6">
                                <label htmlFor="nombre" className="block">Nombre</label>
                                    <InputText 
                                            name="nombre" 
                                            value={formik.values.nombre}
                                            onChange={formik.handleChange}
                                            placeholder="Nombre"
                                            className={formik.errors.nombre && formik.touched.nombre ? 'p-invalid' : null}
                                            />
                                            {formik.errors.nombre && formik.touched.nombre ? (
                                                <small className="p-error block">{formik.errors.nombre}</small>
                                            ) : null}
                                </div>

                                <div className="field mb-4 col-6">
                                <label htmlFor="apellidos" className="block">Apellidos</label>
                                <InputText 
                                    name="apellidos" 
                                    value={formik.values.apellidos}
                                    onChange={formik.handleChange}
                                    placeholder="Apellidos"
                                    className={formik.errors.apellidos && formik.touched.apellidos ? 'p-invalid' : null}
                                    />
                                      {formik.errors.apellidos && formik.touched.apellidos ? (
                                                <small className="p-error block">{formik.errors.apellidos}</small>
                                            ) : null}
                                </div>
                                <div className="field mb-4 col-6">
                                <label htmlFor="email" className="block">Email</label>
                                    <InputText 
                                            name="email"
                                            value={formik.values.email}
                                            onChange={formik.handleChange}
                                            placeholder="Email"
                                            className={formik.errors.email && formik.touched.email ? 'p-invalid' : null}
                                    />
                                     {formik.errors.email && formik.touched.email ? (
                                                <small className="p-error block">{formik.errors.email}</small>
                                            ) : null}
                                </div>


                                <div className="field mb-4 col-6">
                                <label htmlFor="newPassword" className="block">Contraseña</label>

                                    <InputText 
                                            name="newPassword" 
                                            value={formik.values.newPassword}
                                            onChange={formik.handleChange}
                                            placeholder="Contraseña"
                                            className={formik.errors.newPassword && formik.touched.newPassword ? 'p-invalid' : null}                                    
                                            />
                                               {formik.errors.newPassword && formik.touched.newPassword ? (
                                                <small className="p-error block">{formik.errors.newPassword}</small>
                                            ) : null}
                                </div>
                                <div className="field mb-4 col-6">
                                <label htmlFor="telefono" className="block">Teléfono</label>
                                    <InputText 
                                            name="telefono" 
                                            value={formik.values.telefono}
                                            onChange={formik.handleChange}
                                            placeholder="Teléfono"
                                            className={formik.errors.telefono && formik.touched.telefono ? 'p-invalid' : null}                                                                                
                                            />
                                               {formik.errors.telefono && formik.touched.telefono ? (
                                                <small className="p-error block">{formik.errors.telefono}</small>
                                            ) : null}
                                </div>

                                <div className="field mb-4 col-6">
                                <label htmlFor="telefono" className="block">Permisos</label>

                                <MultiSelect
                                        name="roles"
                                        value={formik.values.roles}
                                        options={
                                            [ {
                                                "name": "ROLE_ADMIN",
                                                "label": "Admin"
                                            },
                                            {
                                                "name": "ROLE_GESTOR",
                                                "label": "Gestor" 
                                            }]
                                        }
                                        onChange={formik.handleChange}
                                        optionLabel="label"
                                        placeholder="Selecciona un Rol"
                                        className={formik.errors.roles && formik.touched.roles ? 'p-invalid' : null}
                                    />
                                    {formik.errors.roles && formik.touched.roles ? (
                                        <small className="p-error block">{formik.errors.roles}</small>
                                    ) : null}
                                </div>
                               
                  
                                <div className="field mb-4 col-12">
                                <Checkbox 
                                        name="activo"   
                                        onChange={formik.handleChange}
                                        checked={formik.values.activo}/>
                                <span className="ml-2 text-900">Activo</span>

                                </div>
                            </VisiblePorRol>
      
                            <div className="col-12">
                                <Button type="submit" disabled={isSubmitting} label={isSubmitting ? 'Enviando' : 'Guardar cambios'} className="w-auto mt-3" />
                                <Button label="Cerrar" type="button" className="w-auto mt-3 ml-3 p-button-secondary" onClick={onCerrar}/>
                            </div>
                        </div>
                    </div>
                </div>
               
            </div>
        </form>
 
    )
}

export default UsuarioForm;