import { useState, useCallback, useEffect } from 'react';

import { ApiConstants, postApi } from '../../api';
import { useUserState } from '../../context/UserContext';
import { useFilterState } from '../../context/FilterContext';


const useClientes = (props) => {
    const { userState } = useUserState();
    const [items, setItems] = useState([]);
    const [cargando, setCargando] = useState(true);
    const [creando, setCreando] = useState(false);

    const [status, setStatus] = useState(0);
    const [statusText, setStatusText] = useState('');
    const [totalItems, setTotalItems] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const { filtersApp, setFiltro } = useFilterState();

    const filtroItems = props?.filtro || filtersApp.cliente;


    const nuevoItem = () => {
        setCreando(true);
    }

    const dejarDeCrear = () => {
        setCreando(false);
    }


    const setFiltroState = ({global, page, activo}) => {
        setFiltro({cliente: {global: global, page: page, activo: activo}})
    }

    const loadItems = useCallback(
        () => {
            if (filtroItems !== undefined && filtroItems !== null){
                postApi(ApiConstants.CLIENTES.LISTA, userState.user.token, filtroItems)
                .then(response => {
                    if (response){
                        setItems([...response.data.data]);
                        
                        setTotalItems(response.data.totalItems);
                        setTotalPages(response.data.totalPages);
                        setCurrentPage(response.data.currentPage);
                        setStatus(response.data.status);
                        setStatusText(response.data.statusText);
                        
                        setCargando(false);
                    }
                });
            }
        },
        [filtroItems, userState.user.token],
    );



    useEffect(() => {
        loadItems();
    }, [loadItems]);
    

    return {
        cargando,
        creando,
        dejarDeCrear,
        loadItems,
        nuevoItem,
        totalItems,
        totalPages,
        currentPage,
        status,
        statusText,
        filtroItems,
        setFiltroState,
        items
    }

};

export default useClientes;