import { useState, useEffect } from 'react';

import { ApiConstants, getApi } from '../../api';
import { useUserState } from '../../context/UserContext';

const useUsuarios = () => {
    const { userState } = useUserState();
    const [usuarios, setUsuarios] = useState([]);
    const [cargando, setCargando] = useState(true);
    const [creando, setCreando] = useState(false);

    const [status, setStatus] = useState(0);
    const [statusText, setStatusText] = useState('');
    const [totalItems, setTotalItems] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);


    const nuevoUsuario = () => {
        setCreando(true);
    }

    const dejarDeCrear = () => {
        setCreando(false);
    }


    useEffect(() => {
        getApi(ApiConstants.USUARIOS.LISTA, userState.user.token)
        .then(response => {
        if (response){
          setUsuarios([...response.data.data]);
          
          setTotalItems(response.data.totalItems);
          setTotalPages(response.data.totalPages);
          setCurrentPage(response.data.currentPage);
          setStatus(response.data.status);
          setStatusText(response.data.statusText);
          
          setCargando(false);
        }
        });
    }, [userState.user.token]);
    
    return {
        cargando,
        creando,
        dejarDeCrear,
        nuevoUsuario,
        totalItems,
        totalPages,
        currentPage,
        status,
        statusText,
        usuarios
    }

};

export default useUsuarios;