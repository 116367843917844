/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate } from "react-router-dom";
import { Button } from 'primereact/button';
import Card from "../../components/Cards/Card";
import { ProgressSpinner } from 'primereact/progressspinner';
import useServicio from './useServicio';
import ServicioForm from './Form/ServicioForm';

const Servicio = () => {
  const navigate = useNavigate();

  const { cargando, servicio, setServicio, mostrarBotonEditar, editar, editando, noEditar } = useServicio();

  if (cargando === true) {
    return (
      <div className="flex h-full align-items-center ">
        <ProgressSpinner/>
      </div>
    );
  }



  if (!editando) {
    return (

      <Card>

        <div className="col-12">

          <div className="flex md:align-items-center md:justify-content-between surface-border p-0 flex-column md:flex-row">

            <div className="font-medium text-3xl text-900 mb-3">Servicio nº {servicio.id}
            </div>

            <div className="flex justify-content-between">
              {mostrarBotonEditar
                ?
                <Button
                  icon="pi pi-user-edit"
                  label="Editar"
                  className="p-button-outlined mt-2 md:mt-0 mr-2"
                  onClick={editar}
                />
                : null
              }
              <Button
                className="p-button-rounded p-button-secondary"
                icon="pi pi-arrow-left" onClick={() => navigate(-1)} />
            </div>
          </div>
        </div>


        <div className="grid grid-nogutter border-top-1 surface-border pt-2">
          <div className="col-12 md:col-6 p-3">
            <div className="text-500 font-medium mb-2">Nombre</div>
            <div className="text-900">{servicio.nombre}</div>
          </div>
          <div className="col-12 md:col-6 p-3">
            <div className="text-500 font-medium mb-2">Descripcion</div>
            <div className="text-900">{servicio.descripcion}</div>
          </div>
          <div className="col-12 md:col-6 p-3">
            <div className="text-500 font-medium mb-2">Periodicidad</div>
            <div className="text-900">{servicio.periodicidad} dias</div>
          </div>

          <div className="col-12 md:col-6 p-3">
            <div className="text-500 font-medium mb-2">Importe</div>
            <div className="text-900">{servicio.pvp} €</div>
          </div>
        </div>
      </Card>
    );
  }

  return (
    <ServicioForm servicio={servicio} onUpdate={setServicio} onCerrar={noEditar} />
  );

};

export default Servicio;
