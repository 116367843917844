import { useState, useRef } from "react";
import { useForm } from "react-hook-form";

import logo from "../assets/images/logo.png";
import bgImg from "../assets/images/bglogin.jpg";

import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Message } from 'primereact/message';
import {useUserState} from '../context/UserContext';

const Login = () => {
  const { userState, login } = useUserState();


  const [passwordShown, setPasswordShown] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const toast = useRef();


  const handleLogin = (payload) => {

    login(payload).then((response) => {
      if (!response) {
          toast.current.show({
          severity: "error",
          summary: "Error",
          detail: `${userState.errorMessage}`
        });
      }
    })
    .catch((response) => {
    });
  };



  return (
    <>
      <img
        src={bgImg}
        alt="NaturGym"
        className="h-screen w-screen absolute z-0 opacity-20 bg-cover"
      />

      <div className="flex justify-content-center align-items-center h-screen bg-blue-100">
        <Toast ref={toast} />
        <div className="surface-card p-6 shadow-4 border-round w-30rem h-auto z-1">
          <div className="flex align-items-center justify-content-center flex-column">
            <img src={logo} alt="hyper" height={120} className="mb-3" />
            {
              userState.errorMessage ?
                <Message severity="error" text={userState.errorMessage} /> : null
            }
          </div>

          <form onSubmit={handleSubmit((data) => handleLogin(data))}>
            <label htmlFor="email" className="block text-900 font-medium mb-2">
              Email
            </label>
            <InputText
              {...register("email", {
                required: "Introduzca un email.",
              })}
              className="w-full mb-3"
            />
            <div className="-mt-2 text-xs m-1 mb-3 text-red-500">
              {errors.email?.message}
            </div>

            <label
              htmlFor="password"
              className="block text-900 font-medium mb-2"
            >
              Contraseña
            </label>
            <div className="flex relative">
              <InputText
                {...register("password", {
                  required: "Introduzca una contraseña.",
                })}
                type={passwordShown ? "text" : "password"}
                className="w-full mb-3"
              />
              <i
                onClick={() => {
                  setPasswordShown(!passwordShown);
                }}
                className={
                  passwordShown
                    ? "pi pi-eye-slash absolute bottom-50 right-0 mr-3 hover:text-blue-700"
                    : "pi pi-eye absolute bottom-50 right-0 mr-3 hover:text-blue-700"
                }
              ></i>
            </div>
            <div className="mb-4 text-xs m-1 text-red-500">
              {errors.password?.message}
            </div>

      
            <Button type="submit" label="Acceder" icon="pi pi-user" className="w-full" disabled={userState.loading} />
          </form>
        </div>
      </div>
    </>
  );
}

export default Login;