import { useRef, useState, useContext } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import { Messages } from 'primereact/messages';
import { useFormik } from 'formik';
import ContratoFormSchema from './ContratoFormSchema';
import { ApiConstants, postApi } from '../../../api';
import useServicios from '../../Servicios/useServicios';
import useClientes from '../../Clientes/useClientes';
import { UserContext } from '../../../context/UserContext';
import { dameBooleano } from '../../../utils/tipos';

let mensajes;


const ContratoForm = ({ contrato, onCerrar, onUpdate }) => {

    const { userState } = useContext(UserContext);

    mensajes = useRef(null);

    const [pvp, setPvP] = useState(contrato?.servicio?.pvp || 0);
    const  {items:servicios } = useServicios();
    const {items:clientes, filtroItems:filtroClientes, setFiltroState:setFiltroClientes } = useClientes({ id: contrato?.cliente?.id });
    const [isSubmitting, setSubmiting] = useState(false);

    const onFiltroCliente = (event) => {
        let _filterValue = event.filter;
        setFiltroClientes({ ...filtroClientes, global: _filterValue, page: 0 });
    }

    const onServicioChange = (e) => {
        let servicio = servicios.find(element => element.id === e.value);
        if (servicio) {
            setPvP(servicio?.pvp);
            formik.setValues({ ...formik.values, periodicidad: servicio.periodicidad });
        }
        formik.handleChange(e);
    }


    const formik = useFormik({
        initialValues: {
            id: contrato?.id || 0,
            cliente: contrato?.cliente?.id || '',
            servicio: contrato?.servicio?.id || '',
            periodicidad: contrato?.periodicidad || 0,
            dto: contrato?.dto || 0,
            fechaVencimiento: contrato?.fechaVencimiento || new Date(),
            renovable: dameBooleano(contrato?.renovable,true),
            activo: dameBooleano(contrato?.activo,true),
            observaciones: contrato?.observaciones || ''
        },
        validationSchema: ContratoFormSchema,
        onSubmit: values => {
            setSubmiting(true);
            //alert(JSON.stringify(values, null, 2));
            values = { ...values, servicio: { id: values.servicio }, cliente: { id: values.cliente } };
            if (values?.id > 0) {
                postApi(ApiConstants.CONTRATOS.UPDATE, userState.user.token, values, null).then(response => {
                    onUpdate(response.data.data);
                    onCerrar();
                });
            } else {
                postApi(ApiConstants.CONTRATOS.CREATE, userState.user.token, values, null).then(response => {
                    onUpdate();
                    onCerrar();
                });
            }

            setSubmiting(false);
        },
    });


    return (
        <>

            <form onSubmit={formik.handleSubmit}>

                <div className="surface-section px-4 py-8 md:px-6 lg:px-8">
                    <Messages ref={mensajes} />

                    <div className="grid">
                        <div className="col-2">
                            <div className="grid formgrid p-fluid">
                                <div className="field mb-4 col-12">
                                    <Checkbox
                                        name="activo"
                                        onChange={formik.handleChange}
                                        checked={formik.values.activo}
                                    />
                                    <span className="ml-2 text-900">Activo</span>
                                </div>

                                <div className="field mb-4 col-12">
                                    <Checkbox
                                        name="renovable"
                                        onChange={formik.handleChange}
                                        checked={formik.values.renovable}
                                    />
                                    <span className="ml-2 text-900">Renovable</span>
                                </div>
                            </div>
                        </div>


                        <div className="col-10 lg:col-8">
                            <div className="grid formgrid p-fluid">

                                <div className="field mb-4 col-6">
                                    <label htmlFor="cliente" className="block">Cliente</label>
                                    {contrato?.cliente?.id

                                        ? <span className="text-xl font-medium">{contrato.cliente.nombreCompleto}</span>
                                        :
                                        <span>
                                            <Dropdown
                                                disabled={contrato?.cliente?.id}
                                                name="cliente"
                                                value={formik.values.cliente}
                                                options={clientes}
                                                filter
                                                onFilter={onFiltroCliente}
                                                showClear
                                                filterBy="nombreCompleto"
                                                onChange={formik.handleChange}
                                                optionLabel="nombreCompleto"
                                                optionValue="id"
                                                placeholder="Selecciona un cliente"
                                                className={formik.errors.cliente && formik.touched.cliente ? 'p-invalid' : null}
                                            />
                                            {formik.errors.cliente && formik.touched.cliente ? (
                                                <small className="p-error block">{formik.errors.cliente}</small>
                                            ) : null}
                                        </span>
                                    }
                                </div>

                                <div className="field mb-4 col-6">

                                    <label htmlFor="servicio" className="block">Servicio</label>
                                    <Dropdown
                                        name="servicio"
                                        value={formik.values.servicio}
                                        options={servicios}
                                        onChange={onServicioChange}
                                        optionLabel="nombre"
                                        optionValue="id"
                                        placeholder="Selecciona un Servicio"
                                        className={formik.errors.servicio && formik.touched.servicio ? 'p-invalid' : null}
                                    />
                                    {formik.errors.servicio && formik.touched.servicio ? (
                                        <small className="p-error block">{formik.errors.servicio}</small>
                                    ) : null}

                                </div>

                                <div className="field mb-4 col-6">
                                    <label htmlFor="fechaVencimiento" className="block">Vencimiento</label>
                                    <Calendar name="fechaVencimiento"
                                        locale="es"
                                        dateFormat="dd/mm/yy"
                                        showIcon
                                        showButtonBar
                                        onChange={formik.handleChange}
                                        value={new Date(formik.values.fechaVencimiento)}
                                        className={formik.errors.fechaVencimiento && formik.touched.fechaVencimiento ? 'p-invalid' : null}
                                    ></Calendar>
                                    {formik.errors.fechaVencimiento && formik.touched.fechaVencimiento ? (
                                        <small className="p-error block">{formik.errors.fechaVencimiento}</small>
                                    ) : null}
                                </div>
                                <div className="field mb-4 col-6">
                                    <label htmlFor="periodicidad" className="block">Periodicidad</label>
                                    <InputText name="periodicidad"
                                        onChange={formik.handleChange}
                                        value={formik.values.periodicidad}
                                    />
                                </div>

                                <div className="field mb-4 col-6">
                                    <label htmlFor="pvp" className="block">Importe</label>
                                    <div className="p-inputgroup">
                                        <InputText className="text-right" name="pvp" disabled value={pvp || ""} placeholder="Importe" />
                                        <span className="p-inputgroup-addon">€</span>
                                    </div>
                                </div>

                                <div className="field mb-4 col-6">
                                    <label htmlFor="dto" className="block">Descuento</label>
                                    <div className="p-inputgroup">
                                        <InputText name="dto"
                                            onChange={formik.handleChange}
                                            value={formik.values.dto}
                                        />
                                        <span className="p-inputgroup-addon">%</span>
                                    </div>
                                </div>


                                <div className="field mb-4 col-12">
                                    <label htmlFor="observaciones" className="block">Observaciones</label>
                                    <div className="p-inputgroup">
                                        <InputTextarea name="observaciones"
                                            onChange={formik.handleChange}
                                            value={formik.values.observaciones}
                                        />
                                    </div>
                                </div>


                                <div className="col-12">
                                    <Button type="submit" disabled={isSubmitting} label={isSubmitting ? 'Enviando' : 'Guardar cambios'} className="w-auto mt-3" />
                                    <Button label="Cerrar" type="button" className="w-auto mt-3 ml-3 p-button-secondary" onClick={onCerrar} />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </form>
        </>
    )
}


export default ContratoForm;