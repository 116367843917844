import { useNavigate } from "react-router-dom";
import Card from "../../components/Cards/Card";
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';

import ClienteForm from './Form/ClienteForm';
import FechaBonica from "../../components/Fechas/FechaBonica";
import AvatarGenero from "../../components/AvatarGenero";


const DireccionTemplate = ({ cliente }) => {
  return(
    <>
    {cliente.direccion ? cliente.direccion+' ' : ''}
    {cliente.cp?.length>0 ? cliente.cp+' ' : ''}
    {cliente.direccion || cliente.cp ? ' ' : ''}

    {cliente.poblacion || cliente.provincia || cliente.pais  ? '- ' : ''}

    {cliente.poblacion ? cliente.poblacion+' ' : ''}
    {cliente.provincia ? '('+cliente.provincia.nombre+') ' : ''}
    {cliente.pais ? cliente.pais.nombre+' ' : ''}
    </>
  );
}



const CardCliente = ({ cliente, setCliente, editar, editando, noEditar}) => {
  const navigate = useNavigate();

  if (!editando){

    return (
      <Card>

        <div className="flex md:align-items-center md:justify-content-between surface-border p-0 flex-column md:flex-row">
          
      
            <div className='font-medium text-3xl text-900 '>
            {cliente.nombreCompleto}
            </div>
          <div className="flex justify-content-between">
            <Button
                          icon="pi pi-user-edit"
                          label="Editar"
                          className="p-button-outlined mt-2 md:mt-0 mr-2 mb-2"
                          onClick={editar}
                        />

              <Button 
                  className="p-button-rounded p-button-secondary"
                  icon="pi pi-arrow-left" onClick={() => navigate(-1)}/>
           </div>
        </div>


        <div className='grid grid-nogutter border-top-1 surface-border pt-2'>

        <div className='col-2'>
        <div className="field mb-4 col-12">
                                      <Checkbox
                                          disabled={true}
                                          name="activo"
                                          checked={cliente.activo}
                                      />
                                      <span className="ml-2 text-900">Activo</span>
                                  </div>

                                  <div className="field mb-4 col-12">
                                      <Checkbox
                                          disabled={true}
                                          name="renovable"
                                          checked={cliente.aceptacondiciones}
                                      />
                                      <span className="ml-2 text-900">Acepta condiciones</span>
                                  </div>


      </div>

         <div className='col-7'>    
          <div className='grid grid-nogutter pt-2'>    
              <div className='col-12 md:col-6 p-3'>
                <div className='text-500 font-medium mb-2'>Email</div>
                <div className='text-900'>{cliente.email}</div>
              </div>
              <div className='col-12 md:col-3 p-3'>
                <div className='text-500 font-medium mb-2'>Teléfono</div>
                <div className='text-900'>{cliente.telefono}</div>
              </div>
              <div className='col-12 md:col-3 p-3'>
                <div className='text-500 font-medium mb-2'>NIF</div>
                <div className='text-900'>{cliente.dni}</div>
              </div>
              <div className='col-12 p-3'>
                <div className='text-500 font-medium mb-2'>Dirección</div>
                <div className='text-900'>
                  <DireccionTemplate cliente={cliente} />
                </div>
              </div>
              <div className='col-12 md:col-6 p-3'>
                <div className='text-500 font-medium mb-2'>Género</div>
                <div className='text-900'>{cliente.genero}</div>
              </div>
              <div className='col-12 md:col-6 p-3'>
                <div className='text-500 font-medium mb-2'>Nacimiento</div>
                <div className='text-900'><FechaBonica fecha={cliente.fechaNacimiento} estilo='CORTO'/></div>
              </div>
             
              <div className='col-12 md:col-12 p-3'>
                <div className='text-500 font-medium mb-2'>Observaciones</div>
                <div className='text-900'>{cliente.comentario}</div>
              </div>
          </div>
         </div>      

         <div className='col-3 pt-2 px-2'>    
            <AvatarGenero cliente={cliente} tipo="opt" className="max-w-full" />
          </div> 
        </div>
      </Card>
    );
  }

  return (
    <ClienteForm cliente={cliente} onUpdate={setCliente} onCerrar={noEditar}/>
  );

};

export default CardCliente;
