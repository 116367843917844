import React from 'react'

import { locale, addLocale } from 'primereact/api';
import { LOCALE_ES } from './utils/constants';

import { UserProvider } from "./context/UserContext";
import { FilterProvider } from "./context/FilterContext";

import AppRoutes from './routes/AppRoutes';


addLocale('es', LOCALE_ES);
locale('es');

export default function App() {

  return (

    <UserProvider>
      <FilterProvider>
        <AppRoutes />
      </FilterProvider>
    </UserProvider>
   
  );
}
