import React, { useRef } from 'react';
import { Button } from "primereact/button";
import { Menu } from 'primereact/menu';
import { Toast } from 'primereact/toast';
import GrafBarraAnual from '../components/Charts/GrafBarraAnual/GrafBarraAnual';
import FactMes from '../components/Dashboard/FactMes/FactMes';

const Dashboard = () => {
    const menu1 = useRef(null);
    const menu2 = useRef(null);
    const toast = useRef(null);
    const items = [{
        label: 'Acciones',
        items: [
            {
                label: 'Actualizar',
                icon: 'pi pi-refresh',
                command: () => {
                    toast.current.show({ severity: 'success', summary: 'Actualizado', detail: 'Datos Actualizados', life: 3000 });
                },
            },
            {
                label: 'Ver completo',
                icon: 'pi pi-upload',
                command: (e) => {
                    window.location.hash = "/dashboard"
                }
            }
        ]
    }
    ];


    return (

        <div className="min-h-screen flex flex-column">
            <Toast ref={toast}></Toast>
            <div className="p-5 flex flex-column flex-auto">
                <div className="grid">
                    <div className="col-12">
                        <div className="surface-card shadow-2 border-round flex p-3 flex-column md:flex-row">
                            <div className="border-bottom-1 md:border-right-1 md:border-bottom-none surface-border flex-auto p-3">
                                <div className="flex align-items-center mb-3">
                                    <i className="pi pi-shopping-cart text-blue-500 text-xl mr-2"></i>
                                    <span className="text-500 font-medium">Facturación mes</span>
                                </div>
                                <span className="block text-900 font-medium mb-4 text-xl">0 €</span>
                                <div className="flex align-items-center">
                                    <i className="pi pi-arrow-down text-pink-500 text-xl mr-2"></i>
                                    <span className="text-pink-500 font-medium ">0</span>
                                </div>
                            </div>
                            <div className="border-bottom-1 md:border-right-1 md:border-bottom-none surface-border flex-auto p-3">
                                <div className="flex align-items-center mb-3">
                                    <i className="pi pi-shopping-cart text-orange-500 text-xl mr-2"></i>
                                    <span className="text-500 font-medium">Facturación año</span>
                                </div>
                                <span className="block text-900 font-medium mb-4 text-xl">0 €</span>
                                <div className="flex align-items-center">
                                    <i className="pi pi-arrow-up text-green-500 text-xl mr-2"></i>
                                    <span className="text-green-500 font-medium ">+0</span>
                                </div>
                            </div>
                            <div className="border-bottom-1 md:border-right-1 md:border-bottom-none surface-border flex-auto p-3">
                                <div className="flex align-items-center mb-3">
                                    <i className="pi pi-users text-cyan-500 text-xl mr-2"></i>
                                    <span className="text-500 font-medium">Clientes</span>
                                </div>
                                <span className="block text-900 font-medium mb-4 text-xl">0</span>
                                <div className="flex align-items-center">
                                    <i className="pi pi-arrow-up text-green-500 text-xl mr-2"></i>
                                    <span className="text-green-500 font-medium ">+0</span>
                                </div>
                            </div>
                            <div className="flex-auto p-3">
                                <div className="flex align-items-center mb-3">
                                    <i className="pi pi-users text-purple-500 text-xl mr-2"></i>
                                    <span className="text-500 font-medium">Contratos</span>
                                </div>
                                <span className="block text-900 font-medium mb-4 text-xl">0</span>
                                <div className="flex align-items-center">
                                    <i className="pi pi-arrow-up text-green-500 text-xl mr-2"></i>
                                    <span className="text-green-500 font-medium ">+0</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 lg:col-6">
                        <div className="surface-card shadow-2 border-round p-4 h-full">
                            <div className="flex align-items-center justify-content-between mb-3">
                                <div className="text-900 font-medium text-xl">Contratos vencidos</div>
                                <div>
                                    <Button icon="pi pi-ellipsis-v" className="p-button-text p-button-plain p-button-rounded" onClick={(event) => menu1.current.toggle(event)} />
                                    <Menu ref={menu1} popup model={items} />



                                </div>
                            </div>
                            {/* <ul className="list-none p-0 m-0">
                        <li className="py-3 border-bottom-1 surface-border flex md:align-items-start md:justify-content-between flex-column md:flex-row">
                            <div className="flex align-items-start mr-0 lg:mr-5">
                                <img src="assets/images/blocks/avatars/circle-big/avatar-f-1.png" alt="avatar-f-1" className="mr-3 w-3rem h-3rem" />
                                <div>
                                    <span className="text-900 font-medium block mb-2">Jane Cooper</span>
                                    <div className="text-700 mb-2">responded to an issue.</div>
                                    <span className="text-blue-500 cursor-pointer">
                                        <i className="pi pi-github text-sm mr-2"></i>
                                        <span>Issue #1185</span>
                                    </span>
                                </div>
                            </div>
                            <span className="block text-500 font-medium ml-7 md:ml-5 mt-2 md:mt-0">14 mins ago</span>
                        </li>
                        <li className="py-3 border-bottom-1 surface-border flex md:align-items-start md:justify-content-between flex-column md:flex-row">
                            <div className="flex align-items-start mr-0 lg:mr-5">
                                <img src="assets/images/blocks/avatars/circle-big/avatar-m-1.png" alt="avatar-m-1" className="mr-3 w-3rem h-3rem" />
                                <div>
                                    <span className="text-900 font-medium block mb-2">Robert Fox</span>
                                    <div className="text-700">changed team size from <span className="text-900">5</span> to <span className="text-900">6</span>.</div>
                                </div>
                            </div>
                            <span className="block text-500 font-medium ml-7 md:ml-5 mt-2 md:mt-0">20 mins ago</span>
                        </li>
                        <li className="py-3 border-bottom-1 surface-border flex md:align-items-start md:justify-content-between flex-column md:flex-row">
                            <div className="flex align-items-start mr-0 lg:mr-5">
                                <img src="assets/images/blocks/avatars/circle-big/avatar-f-2.png" alt="avatar-f-2" className="mr-3 w-3rem h-3rem" />
                                <div>
                                    <span className="text-900 font-medium block mb-2">Kristin Watson Cooper</span>
                                    <div className="text-700 mb-2">created a Q4 presentation to an issue.</div>
                                    <span className="text-blue-500 cursor-pointer">
                                        <i className="pi pi-file-pdf text-sm mr-2"></i>
                                        <span>q4_presentation.pdf</span>
                                    </span>
                                </div>
                            </div>
                            <span className="block text-500 font-medium ml-7 md:ml-5 mt-2 md:mt-0">38 mins ago</span>
                        </li>
                        <li className="py-3 border-bottom-1 surface-border flex md:align-items-start md:justify-content-between flex-column md:flex-row">
                            <div className="flex align-items-start mr-0 lg:mr-5">
                                <img src="assets/images/blocks/avatars/circle-big/avatar-f-3.png" alt="avatar-f-3" className="mr-3 w-3rem h-3rem" />
                                <div>
                                    <span className="text-900 font-medium block mb-2">Annette Black</span>
                                    <div className="text-700">added <span className="text-900">Nico Greenberg</span> to <span className="text-blue-500">Watchlist Tier-1</span>.</div>
                                </div>
                            </div>
                            <span className="block text-500 font-medium ml-7 md:ml-5 mt-2 md:mt-0">1 day ago</span>
                        </li>
                        <li className="py-3 flex md:align-items-start md:justify-content-between flex-column md:flex-row">
                            <div className="flex align-items-start mr-0 lg:mr-5">
                                <img src="assets/images/blocks/avatars/circle-big/avatar-m-2.png" alt="avatar-m-2" className="mr-3 w-3rem h-3rem" />
                                <div>
                                    <span className="text-900 font-medium block mb-2">Floyd Miles</span>
                                    <div className="text-700">has refunded a blue t-shirt for <span className="text-blue-500">79$</span>.</div>
                                </div>
                            </div>
                            <span className="block text-500 font-medium ml-7 md:ml-5 mt-2 md:mt-0">4 days ago</span>
                        </li>
                    </ul> */}
                        </div>
                    </div>
                    <div className="col-12 lg:col-6">
                        <div className="surface-card shadow-2 border-round p-4 h-full">
                            <div className="flex align-items-center justify-content-between mb-4">
                                <div className="text-900 font-medium text-xl">Recibos pendientes</div>
                                <div>
                                    <Button icon="pi pi-ellipsis-v" className="p-button-text p-button-plain p-button-rounded" onClick={(event) => menu2.current.toggle(event)} />
                                    <Menu ref={menu2} popup model={items} />
                                </div>
                            </div>

                            {/* <span className="block text-600 font-medium mb-3">TODAY</span>
<ul className="p-0 mx-0 mt-0 mb-4 list-none">
<li className="flex align-items-center py-2 border-bottom-1 surface-border">
<div className="w-3rem h-3rem flex align-items-center justify-content-center bg-blue-100 border-circle mr-3 flex-shrink-0">
<i className="pi pi-dollar text-xl text-blue-500"></i>
</div>
<span className="text-900 line-height-3">Richard Jones
<span className="text-700">has purchased a blue t-shirt for <span className="text-blue-500">79$</span></span>
</span>
</li>
<li className="flex align-items-center py-2">
<div className="w-3rem h-3rem flex align-items-center justify-content-center bg-orange-100 border-circle mr-3 flex-shrink-0">
<i className="pi pi-download text-xl text-orange-500"></i>
</div>
<span className="text-700 line-height-3">Your request for withdrawal of <span className="text-blue-500 font-medium">2500$</span> has been initiated.</span>
</li>
</ul>

<span className="block text-600 font-medium mb-3">YESTERDAY</span>
<ul className="p-0 m-0 list-none">
<li className="flex align-items-center py-2 border-bottom-1 surface-border">
<div className="w-3rem h-3rem flex align-items-center justify-content-center bg-blue-100 border-circle mr-3 flex-shrink-0">
<i className="pi pi-dollar text-xl text-blue-500"></i>
</div>
<span className="text-900 line-height-3">Keyser Wick
<span className="text-700">has purchased a black jacket for <span className="text-blue-500">59$</span></span>
</span>
</li>
<li className="flex align-items-center py-2 border-bottom-1 surface-border">
<div className="w-3rem h-3rem flex align-items-center justify-content-center bg-pink-100 border-circle mr-3 flex-shrink-0">
<i className="pi pi-question text-xl text-pink-500"></i>
</div>
<span className="text-900 line-height-3">Jane Davis
<span className="text-700">has posted a new questions about your product.</span>
</span>
</li>
<li className="flex align-items-center py-2">
<div className="w-3rem h-3rem flex align-items-center justify-content-center bg-green-100 border-circle mr-3 flex-shrink-0">
<i className="pi pi-arrow-up text-xl text-green-500"></i>
</div>
<span className="text-900 line-height-3">Claire Smith
<span className="text-700">has upvoted your store along with a comment.</span>
</span>
</li>
</ul> */}
                        </div>
                    </div>




                    <div className="col-12">
                        <div className="surface-card shadow-2 border-round p-4">
                            <div className="flex align-items-center justify-content-between mb-3">
                                <div className="text-900 font-medium text-xl">Facturación</div>
                            </div>
                            <div className="flex">    
                            <div className="col-9">
                            <GrafBarraAnual />
                            </div>
                            <div className="col-3 mt-2">
                            <FactMes />
                            </div>
                            </div>


                        </div>
                    </div>

                    {/* <div className="col-4">
                        <div className="surface-card shadow-2 border-round p-4">
                            <div className="flex align-items-center justify-content-between mb-3">
                                <div className="text-900 font-medium text-xl">Facturación</div>
                            </div>
                            <FactMes />
                        
                        </div>
                    </div> */}
                </div>
            </div>
        </div>

    );
};

export default Dashboard;
