import React, { useState, useEffect, useRef, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { Toolbar } from 'primereact/toolbar';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ProgressSpinner } from 'primereact/progressspinner';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Button } from "primereact/button";
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import useIdTemplate from '../../components/Tables/idTemplate';
import Card from "../../components/Cards/Card";
import Paginador from '../../components/Paginador/Paginador';
import { FechaBonicaAtrasadaResaltada } from '../../components/Fechas/FechaBonica';
import useRecibos from './useRecibos';
import { UserContext } from '../../context/UserContext';
import { ApiConstants, getApi, postApiPDF } from '../../api';
import VisiblePorRol from '../../components/Auth/VisiblePorRol';

let navigate;


const pagadoTemplate = (data) => {
  const color = data.pagado === true ? 'green' : 'yellow';
  const estado = data.pagado === true ? 'Si' : 'No';
  return (
    <span className={`bg-${color}-600 text-white p-2`}>{estado}</span>
  )
}


const Recibos = (props) => {
  navigate = useNavigate();

  const { userState } = useContext(UserContext);

  const {
    cargando,
    loadItems,
    totalItems,
    totalPages,
    items,
    filtroItems,
    setFiltroState
  } = useRecibos(props);

  const [selectedRecibos, setSelectedRecibos] = useState([]);
  const idTemplate = useIdTemplate('/recibo');
  const [pagadoFilter, setPagadoFilter] = useState(true);
  const [recibo, setRecibo] = useState(null);
  const [deleteReciboDialog, setDeleteReciboDialog] = useState(false);
  const toast = useRef(null);

  useEffect(() => {
    if (filtroItems.pagado === true) setPagadoFilter(true);
    else if (filtroItems.pagado === false) setPagadoFilter(false);
    else setPagadoFilter('todos');
}, [filtroItems.pagado]);


  const onSiguiente = () => {
    setFiltroState({ ...filtroItems, page: (filtroItems.page) + 1 });
  }

  const onAnterior = () => {
    setFiltroState({ ...filtroItems, page: (filtroItems.page) - 1 });
  }

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    if (filtroItems.global !== value) {
      setFiltroState({ ...filtroItems, global: value, page: 0 });
    }
  }


  const onChanguePagado = (e) => {
    const valueCode = e.target.value;
    let valuePagado = undefined;
    if (valueCode === true) valuePagado = true;
    if (valueCode === false) valuePagado = false;
    if (filtroItems.pagado !== valueCode) {
      setFiltroState({ ...filtroItems, page: 0, pagado: valuePagado });
    }
  }



  const imprimirRecibos = () => {
    const recibosIDs = selectedRecibos.map(e => e.id);
    if (recibosIDs.length > 0) {
      postApiPDF(ApiConstants.RECIBOS.IMPRIMIR_RECIBOS, userState.user.token, recibosIDs, null)
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'recibosnatur.pdf');
          document.body.appendChild(link);
          link.click();
        });
    }
  }

  const hideDeleteReciboDialog = () => {
    setDeleteReciboDialog(false);
  }

  const confirmDeleteRecibo = (recibo) => {
    setRecibo(recibo);
    setDeleteReciboDialog(true);
  }

  const deleteRecibo = () => {
    //let _recibos = items.filter(val => val.id !== recibo.id);
    //setItems(_recibos);
    //const contratosIDs = selectedContratos.map(e => e.id);
    //console.log('contratosIDs:',contratosIDs);
    if (recibo){
      getApi(ApiConstants.RECIBOS.DELETE, userState.user.token, null, recibo.id)
      .then(response => {
       loadItems();
      });
    } 

    setDeleteReciboDialog(false);
    setRecibo(null);
    toast.current.show({ severity: 'success', summary: 'Operación realizada', detail: 'Recibo eliminado', life: 3000 });
  }

  const actionBodyTemplate = (rowData) => {
    return (
        <React.Fragment>
            <VisiblePorRol rol="ROLE_ADMIN">
            <Button icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => confirmDeleteRecibo(rowData)} />
            </VisiblePorRol>
        </React.Fragment>
    );
  }

  const deleteReciboDialogFooter = (
    <React.Fragment>
        <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteReciboDialog} />
        <Button label="Si" icon="pi pi-check" className="p-button-text" onClick={deleteRecibo} />
    </React.Fragment>
  );

  const leftToolBar = (
    <React.Fragment>
      {props.modo !== 'cliente' ?
        <>
          <span className="p-input-icon-left mr-2">
            <i className="pi pi-search" />
            <InputText
              value={filtroItems.global}
              onChange={onGlobalFilterChange}
              placeholder="Buscar..." />
          </span>
        </>
        : null}
      <Dropdown
        value={pagadoFilter}
        options={[
          { name: 'Pagado', value: true },
          { name: 'No pagado', value: false },
          { name: 'Todos', value: 'todos'},
        ]}
        onChange={onChanguePagado}
        optionLabel="name"
        placeholder="Estado"
      />


      {/* <Calendar 
        locale="es"
        dateFormat="dd/mm/yy"
        value={vencimiento} 
        onChange={(e) => setVencimiento(e.value)} 
        showIcon 
        showButtonBar
        placeholder='Vencimiento'/>
        <span>&nbsp;</span>
        <Button
            icon="pi pi-plus"
            label="Generar Recibos"
            className="p-button-outlined mt-2 md:mt-0"
            disabled={selectedContratos?.length===0}
            onClick={generarRecibos}
          /> */}

    </React.Fragment>
  );

  const rightToolBar = (
    <Button
      icon="pi pi-print"
      label={selectedRecibos?.length === 1 ? "Imprimir Recibo" : "Imprimir Recibos"}
      className="p-button-outlined mt-2 md:mt-0"
      disabled={selectedRecibos?.length === 0}
      onClick={imprimirRecibos}
    />
  );




  if (cargando === true) {
    return (
      <div className="flex h-full align-items-center ">
        <ProgressSpinner />
      </div>
    );
  }



  return (
    <Card>
      <Toast ref={toast} />
      <h1 className="text-2xl">Recibos</h1>

      <Toolbar left={leftToolBar} right={rightToolBar} />


      <DataTable
        value={items}
        lazy
        responsiveLayout="scroll"
        selection={selectedRecibos}
        onSelectionChange={e => setSelectedRecibos(e.value)}

        onRowClick={(e) => {
          const { id } = e.data;
          navigate(`/recibo/${id}`);
        }}

        rowClassName={"hover:bg-blue-50 cursor-pointer"}
      >

        <Column selectionMode="multiple" headerStyle={{ width: '3em' }}></Column>
        <Column body={idTemplate} header="Id" />
        <Column field="contrato.id" header="Contrato" />
        <Column field="contrato.cliente.nombreCompleto" header="Cliente" />
        <Column header="Fecha" body={(data) => <FechaBonicaAtrasadaResaltada fecha={data.fecha} estilo='CORTO' />} />
        <Column header="Importe" body={(data) => `${data.importe} €`} align="right" />
        <Column field="detalle" header="Servicio" />
        <Column header="Pagado" body={pagadoTemplate} align="center" headerStyle={{ width: '3em' }} />
        <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '8rem' }}></Column>
      </DataTable>

      <Paginador
        nombre="contratos"
        totalItems={totalItems}
        numPagina={filtroItems?.page}
        totalPaginas={totalPages}
        onAnterior={onAnterior}
        onSiguiente={onSiguiente}
      />

      <Dialog visible={deleteReciboDialog} style={{ width: '450px' }} header="Confirmación de borrado" modal footer={deleteReciboDialogFooter} onHide={hideDeleteReciboDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem'}} />
                    {recibo && <span>¿Seguro que quiere borrar el recibo num <strong>{recibo.id}</strong> de <strong>{recibo.contrato.cliente.nombreCompleto}</strong>?</span>}
                </div>
      </Dialog>
    </Card>
  );


};

export default Recibos;
