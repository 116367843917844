import { useRef, useState,useContext } from 'react';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import { Messages } from 'primereact/messages';
import { useFormik } from 'formik';
import ServicioFormSchema from './ServicioFormSchema';
import { ApiConstants, postApi } from '../../../api';
import {UserContext} from '../../../context/UserContext';


let mensajes;


const ServicioForm = ({ servicio, onCerrar, onUpdate }) => {
    const { userState } = useContext(UserContext);

    mensajes = useRef(null);

    const [isSubmitting, setSubmiting] = useState(false);

    const formik = useFormik({
        initialValues: {
            id: servicio?.id || '',
            nombre: servicio?.nombre || '',
            descripcion: servicio?.descripcion || '',
            periodicidad: servicio?.periodicidad || 0,
            pvp: servicio?.pvp || 0,
            activo: servicio?.activo || false
        },
        validationSchema: ServicioFormSchema,
        onSubmit: values => {
            setSubmiting(true);
            //alert(JSON.stringify(values, null, 2));
            if (servicio !== null) {
                postApi(ApiConstants.SERVICIOS.UPDATE, userState.user.token, values, null).then(response => {
                    onUpdate(response.data.data);
                    onCerrar();
                });
            } else {
                postApi(ApiConstants.SERVICIOS.CREATE, userState.user.token, values, null).then(response => {
                    onUpdate();
                    onCerrar();
                });
            }

            setSubmiting(false);
        },
    });


    return (
        <>

            <form onSubmit={formik.handleSubmit}>

                <div className="surface-section px-4 py-8 md:px-6 lg:px-8">
                    <Messages ref={mensajes} />

                    <div className="grid">
                        <div className="col-12 lg:col-10">
                            <div className="grid formgrid p-fluid">

                                <div className="field mb-4 col-6">
                                    <label htmlFor="cliente" className="block">Nombre</label>
                                    <InputText
                                        name="nombre"
                                        value={formik.values.nombre}
                                        onChange={formik.handleChange}
                                        placeholder="Selecciona un nombre"
                                        className={formik.errors.nombre && formik.touched.nombre ? 'p-invalid' : null}
                                    />
                                    {formik.errors.nombre && formik.touched.nombre ? (
                                        <small className="p-error block">{formik.errors.nombre}</small>
                                    ) : null}
                                </div>

                                <div className="field mb-4 col-6">
                                    <label htmlFor="cliente" className="block">Descripcion</label>
                                    <InputText
                                        name="descripcion"
                                        value={formik.values.descripcion}
                                        onChange={formik.handleChange}
                                        placeholder="Selecciona una descripción"
                                    />
                                </div>

        
                                <div className="field mb-4 col-6">
                                    <label htmlFor="periodicidad" className="block">Periodicidad</label>
                                    <InputText 
                                        name="periodicidad"
                                        onChange={formik.handleChange}
                                        value={formik.values.periodicidad}
                                        className={formik.errors.periodicidad && formik.touched.periodicidad ? 'p-invalid' : null}
                                    />
                                     {formik.errors.periodicidad && formik.touched.periodicidad ? (
                                        <small className="p-error block">{formik.errors.periodicidad}</small>
                                    ) : null}
                                </div>

                                <div className="field mb-4 col-6">
                                    <label htmlFor="pvp" className="block">Importe</label>
                                    <div className="p-inputgroup">
                                        <InputText 
                                            name="pvp" 
                                            onChange={formik.handleChange}
                                            value={formik.values.pvp}
                                            className={formik.errors.pvp && formik.touched.pvp ? 'p-invalid text-right' : 'text-right'}
                                            placeholder="Importe" />
                                        <span className="p-inputgroup-addon">€</span>
                                    </div>
                                    {formik.errors.pvp && formik.touched.pvp ? (
                                        <small className="p-error block">{formik.errors.pvp}</small>
                                    ) : null}
                                </div>

                             

                                <div className="field mb-4 col-12">
                                    <Checkbox
                                        name="activo"
                                        onChange={formik.handleChange}
                                        checked={formik.values.activo}
                                    />
                                    <span className="ml-2 text-900">Activo</span>
                                </div>


                                <div className="col-12">
                                    <Button type="submit" disabled={isSubmitting} label={isSubmitting ? 'Enviando' : 'Guardar cambios'} className="w-auto mt-3" />
                                    <Button label="Cerrar" type="button" className="w-auto mt-3 ml-3 p-button-secondary" onClick={onCerrar} />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </form>
        </>
    )
}


export default ServicioForm;