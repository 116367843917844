const { DateTime } = require("luxon");


export const formatFecha = (fechaString) => {
    const fecha = DateTime.fromISO(fechaString).setLocale('es');

    return fecha.toLocaleString({ month: 'long', day: 'numeric', year: 'numeric' });
};


export const formatFechaCorto = (fechaString) => {
    const fecha = DateTime.fromISO(fechaString).setLocale('es').toFormat('dd/MM/yyyy');
    return fecha.toString();
};





const FechaBonica = ({ fecha, estilo, mensajeError, resalta }) => {
    if (!fecha) {
        return (<> {mensajeError ? mensajeError: 'Sin fecha'}</>)
    }

    return (
        <>
        {estilo === 'CORTO' 
            ? formatFechaCorto(fecha)
            : formatFecha(fecha)
        }
        </>
    );
};


export const FechaBonicaAtrasadaResaltada = ({fecha, estilo}) => {
    const color = (new Date(fecha).getTime()) < (new Date().getTime()) ? 'red' : 'black';
    return(
      <span className={`text-${color}-600`}>
      <FechaBonica fecha={fecha} estilo={estilo} />
      </span>
    )
  }

export default FechaBonica;