import React, {useContext} from "react";
import { Navigate } from "react-router-dom";

import {UserContext} from '../context/UserContext';


const AppRoute = ({ children, isPrivate, isNologged, rol }) => {

    const { userState } = useContext(UserContext);

    if (isNologged && Boolean(userState.user.token)){
        return <Navigate to={{ pathname: "/" }} />
    }

    if (isPrivate && !Boolean(userState.user.token)) {
        return <Navigate to={{ pathname: "/login" }} />
    }

    if (rol && 
        !userState.user.roles.includes(rol) &&
        !userState.user.roles.includes('ROLE_'+rol)) {
        return <Navigate to={{ pathname: "/403" }} />
    }

    return children;
}


export default AppRoute