import React, { useState, useEffect } from 'react'
import { useUserState } from '../../../context/UserContext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ApiConstants, getApi } from '../../../api';
import "./FactMes.css";

const FactMes = () => {

    const { userState } = useUserState();
    const [data, setData] = useState([])


    useEffect(() => {
        getApi(ApiConstants.ESTADISTICAS.FACTURACIONOBJ, userState.user.token, null)
            .then(
                (response) => {
                    setData(response.data);
                });

    }, []);

    const formatCurrency = (value) => {
        return value.toLocaleString('es-ES', { style: 'currency', currency: 'EUR' });
    }
    
    const priceBodyTemplate = (rowData) => {
        return formatCurrency(rowData.importe);
    }
    

    return (
        <DataTable value={data} responsiveLayout="scroll" className='datatable-factmes'>
        <Column field="nombreMes" header="Mes"></Column>
        <Column field="anyo" header="Año"></Column>

        <Column body={priceBodyTemplate} header="Facturación"></Column>
    
    </DataTable>
    );

}

export default FactMes;