import { ProgressSpinner } from 'primereact/progressspinner';

import CardCliente from './CardCliente';
import Contratos from '../Contratos';
import Recibos from '../Recibos';

import useCliente from './useCliente';

const Cliente = () => {

  const { cargando, cliente, setCliente, editar, editando, noEditar } = useCliente();

  const filtroContratos = {
    cliente: cliente,
    activo: true
};

  const filtroRecibos = {
    cliente: cliente,
    activo: true
  };

  if (cargando === true) {
    return (
      <div className="flex h-full align-items-center ">
        <ProgressSpinner/>
      </div>
    );
  }


  return (
  <>
    <CardCliente 
          cliente={cliente} 
          setCliente={setCliente}
          editando={editando}
          editar={editar}
          noEditar={noEditar}
          />
 
    <Contratos filtro={filtroContratos} modo="cliente" />

    <Recibos filtro={filtroRecibos} modo="cliente" />

  </>  
  );
};

export default Cliente;