import * as yup from 'yup';

const ServicioFormSchema = yup.object().shape({
    periodicidad: yup.number()
    .required('Indique periodicidad del servicio'),
    pvp: yup.number()
    .required('El servicio necesita un precio'),
    nombre: yup.string()
    .required('Escribir una nombre'),

    activo: yup.boolean(),
});

export default ServicioFormSchema;