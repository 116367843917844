import React, { useState, createContext } from 'react'
import axios from "axios";
import { API_HOST } from "../../utils/constants";
import { ApiConstants } from '../../api';

const usuarioEnBlanco = {
    apellidos: '',
    roles: [],
    id: 0,
    version: 0,
    nombre: '',
    email: '',
    token: ''
}

const getUsuarioEnMemoria = () =>{
    let resultado = localStorage.getItem('currentUser')
        ? JSON.parse(localStorage.getItem('currentUser'))
        : usuarioEnBlanco;

    return resultado;
}

let UserStateEnMemoria = {
    user: getUsuarioEnMemoria(),
    loading: false,
    errorMessage: ''
};
    
const resetUsuario = () =>{
    localStorage.removeItem('currentUser');
    UserStateEnMemoria = {...UserStateEnMemoria, user: usuarioEnBlanco} 
}

if (UserStateEnMemoria.user.token) {
    const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${UserStateEnMemoria.user.token}`,
    };

    fetch(`${API_HOST}/${ApiConstants.AUTH.TOKEN_VALIDATE}`,
        {
            method: 'GET',
            headers: headers
        })
        .then(function (response) {
            if (!response.ok) {
                resetUsuario();
            }
        })
        .catch(function (error) {
            console.log('Hubo un problema con la petición Fetch:' + error.message);
        });
}


// CREACION DEL CONTEXTO

export const UserContext = createContext({ UserStateEnMemoria });


export const useUserState = () => {
    const context = React.useContext(UserContext);
    if (context === undefined) {
        throw new Error("useUserState debe usarse dentro de un UserProvider");
    }
    return context;
}


export const UserProvider = ({ children }) => {

    const [userState, setUserState] = useState(UserStateEnMemoria);

    const login = (loginPayload) => {
        return new Promise((resolve, reject) => {
            const url = `${API_HOST}/${ApiConstants.AUTH.LOGIN}`;
            const { email, password } = loginPayload;

            let responseAxios = axios.post(url, null, {
                params: {
                    email,
                    password,
                },
            });

            responseAxios.then((response) => {
                if (response?.data?.status === 200) {
                    let data = response.data;
                    if (data.email) {
                        localStorage.setItem('currentUser', JSON.stringify(data));
                        setUserState({
                            ...userState,
                            user: data,
                            errorMessage: ''
                        });
                    }
                }
                else {
                    setUserState({
                        ...userState,
                        errorMessage: 'Usuario y contraseña no válidos'
                    });
                }
            }
            )
            .then(() => resolve(true)) 
            .catch((response) => {
                    setUserState({
                        ...userState,
                        errorMessage: 'Usuario y contraseña no válidos'
                    });
                    reject(false);
            });
            
        });
    };

    const logout = () => {

        const url = `${API_HOST}/${ApiConstants.AUTH.LOGOUT}`;
        const { token } = userState.user;

        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
        };

        let response = axios.get(url, requestOptions);

        response.then(() => {
            resetUsuario();
            setUserState({ ...UserStateEnMemoria });
        });
        return response;
    };


    return (
        <UserContext.Provider value={{ userState, login, logout }}>
            {children}
        </UserContext.Provider>
    );
}