import React, { useState, useEffect } from 'react'
import { Chart } from "primereact/chart";
import { useUserState } from '../../../context/UserContext';
import { ApiConstants, getApi } from '../../../api';

import "./GrafBarraAnual.css"


const GrafBarraAnual = () => {
  const { userState } = useUserState();

  const [mesAnio, setMesAnio] = useState([])
  const [tituloAnio, setTituloAnio] = useState([]);
  const [dataAnio, setDataAnio] = useState([]);
  const [colorAnio, setColorAnio] = useState([])
  const [hoverColor, setHoverColor] = useState([]);


  useEffect(()=>{
      getApi(ApiConstants.ESTADISTICAS.FACTURACION, userState.user.token, null)
      .then(
        (response) =>{
           setMesAnio(response.data.labels)
           setTituloAnio(response.data.datasets[0].label)
           setDataAnio(response.data.datasets[0].data)
           setColorAnio(response.data.datasets[0].backgroundColor)
           setHoverColor(response.data.datasets[0].hoverBackgroundColor)
      });

  }, []);

    const dataAnual = {
        labels: mesAnio,
        datasets: [
          {
            data: dataAnio,
            fill: false,
            backgroundColor: colorAnio,
            hoverBackgroundColor: hoverColor,
          },
        ],
    };
    
    const optionsA = {
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: .8,
        plugins: {
          title: {
            display: false,
          },
          legend: {
            display: false,
          },
          fillStyle: "white",
        },
    };


    
    
  return (
       <Chart type="bar" 
            data={dataAnual} 
            options={optionsA} 
            width="100%"
            height='250px'
            className="barras-anual" />
  )
}

export default GrafBarraAnual