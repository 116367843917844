/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react';

import { useNavigate } from "react-router-dom";

import Card from "../../components/Cards/Card";
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { ProgressSpinner } from 'primereact/progressspinner';

import formatFecha from '../../utils/formatFecha';
import useRecibo from './useRecibo';
import ReciboForm from './Form/ReciboForm';
import { UserContext } from '../../context/UserContext';

import { ApiConstants, postApiPDF } from '../../api';


const Recibo = () => {
  const navigate = useNavigate();
  const { userState } = useContext(UserContext);

  const { cargando, recibo, setRecibo, mostrarBotonEditar, editar, editando, noEditar } = useRecibo();


  const imprimirRecibo = () => {
    const recibosIDs = [recibo.id];
    if (recibosIDs.length > 0) {
      postApiPDF(ApiConstants.RECIBOS.IMPRIMIR_RECIBOS, userState.user.token, recibosIDs, null)
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          //link.target = '_blank';
          link.setAttribute('download', `recibo-${recibo.id}.pdf`);
          document.body.appendChild(link);
          link.click();
        });
    }
  }


  if (cargando === true) {
    return (
      <div className="flex h-full align-items-center ">
        <ProgressSpinner />
      </div>
    );
  }



  if (!editando) {
    return (

      <Card>
        <div className="col-12">

          <div className="flex md:align-items-center md:justify-content-between surface-border p-0 flex-column md:flex-row">

            <div className="font-medium text-3xl text-900 mb-3">Recibo nº {recibo.id}
            </div>

            <div className="flex justify-content-between">
              {mostrarBotonEditar
                ?
                <Button
                  icon="pi pi-user-edit"
                  label="Editar"
                  className="p-button-outlined mt-2 md:mt-0 mr-2"
                  onClick={editar}
                />
                : null
              }

              <Button
                icon="pi pi-print"
                label="Imprimir"
                className="p-button-outlined mt-2 md:mt-0 mr-2"
                onClick={imprimirRecibo}
              />

              <Button
                className="p-button-rounded p-button-secondary"
                icon="pi pi-arrow-left" onClick={() => navigate(-1)} />
            </div>
          </div>
        </div>


        <div className="grid grid-nogutter border-top-1 surface-border pt-2">

          <div className="col-2">
            <div className="grid formgrid p-fluid">
              <div className="field mb-4 col-12">
                <Checkbox
                  disabled={true}
                  name="activo"
                  checked={recibo.activo}
                />
                <span className="ml-2 text-900">Activo</span>
              </div>

              <div className="field mb-4 col-12">
                <Checkbox
                  disabled={true}
                  name="renovable"
                  checked={recibo.pagado}
                />
                <span className="ml-2 text-900">Pagado</span>
              </div>
            </div>
          </div>


          <div className="col-10">

            <div className="grid">
              <div className="col-12  p-3">
                <div className="text-500 font-medium mb-2">Cliente</div>
                <div className="text-900 text-xl font-medium"><a href={`/#/cliente/${recibo.contrato?.cliente?.id}`}>{recibo.contrato?.cliente?.nombreCompleto}</a></div>
              </div>

              <div className="col-2  p-3">
                <div className="text-500 font-medium mb-2">Contrato</div>
                <div className="text-900"><a href={`/#/contrato/${recibo.contrato?.id}`}>{recibo.contrato?.id}</a></div>
              </div>

              <div className="col-8 p-3">
                <div className="text-500 font-medium mb-2">Detalle</div>
                <div className="text-900">{recibo.detalle}</div>
              </div>

              <div className="col-12 p-3">
                <div className="text-500 font-medium mb-2">Importe</div>
                <div className="text-900">{recibo.importe} €</div>
              </div>

              <div className="col-12 p-3">
                <div className="text-500 font-medium mb-2">Fecha</div>
                <div className="text-900">{formatFecha(recibo.fecha)}</div>
              </div>
              {recibo.pagado &&
                <div className="col-12 p-3">
                  <div className="text-500 font-medium mb-2">Fecha de pago</div>
                  <div className="text-900">{formatFecha(recibo.fechaPago)}</div>
                </div>
              }


              <div className="col-12 p-3">
                <div className="text-500 font-medium mb-2">Observaciones</div>
                <div className="text-900">{recibo.observaciones}</div>
              </div>
            </div>
          </div>
        </div>

      </Card>
    );
  }

  return (
    <ReciboForm recibo={recibo} onUpdate={setRecibo} onCerrar={noEditar} />
  );

};
export default Recibo;
